<template>
    <section>
        <Loader v-if="isLoading" />
        <template v-else>
            <div v-if="allFiles.length" class="files-container">
                <div class="files-grid" >
                    <FileItem
                        v-for="file in allFiles"
                        :key="file.id"
                        :file="file"
                        @deleteImage="deleteHandler"
                    />
                </div>
                <div>
                    <button @click="loadMoreHandler" class="files-load-btn">Cargar más</button>
                </div>
            </div>
            <div v-else>
                <h2>No hay ningún archivo</h2>
            </div>
        </template>
    </section>
</template>

<script>
import { createToast } from '../utils/utils';

import Loader from '../components/generic/Loader.vue';

import FileItem from '../components/cms/FileItem.vue';
import { filterAndFetch } from '../services/firebase/filter';
import { deleteFileById } from '../services/firebase/filesCRUD';

export default {
    components: {
        FileItem,
        Loader
    },
    name: 'Files',
    data() {
        return {
            loading: false,
            allFiles: [],
            filterConfiguration: {
                collection: 'Files',
                criteria: 'id',
                order: 'asc',
                limit: 10,
            }
        };
    },
    methods: {
        fetchData() {
            this.loading = true;
            filterAndFetch(this.filterConfiguration)
                .then(res => {
                    this.allFiles = res.results;
                    this.filterConfiguration.startAfter = res.startAfter;
                    this.loading = false;
                })
                .catch(({ msg }) => {
                    createToast({
                        text: msg,
                        type: 'error',
                    });
                });
        },
        loadMoreHandler() {
            this.loading = true;
            filterAndFetch(this.filterConfiguration)
                .then(res => {
                    this.allFiles.push(...res.results);
                    this.filterConfiguration.startAfter = res.startAfter;
                    this.loading = false;
                })
                .catch(({ msg }) => {
                    createToast({
                        text: msg,
                        type: 'error',
                    });
                });
        },
        deleteHandler(id) {
            // this.loading = true
            deleteFileById(id)
                .then(() => {
                    const newArray = this.allFiles.filter(file => file.id !== id);
                    this.allFiles = newArray;
                    // this.loading = false
                })
                .catch(({ msg }) => {
                    createToast({
                        text: msg,
                        type: 'error',
                    });
                });
        }
    },
    computed: {
        isLoading() {
            return this.loading;
        },
    },
    created() {
        this.fetchData();
    },
};
</script>

<style scoped>
.files-container {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 90%;
    margin: auto;
    box-shadow: 2px 2px 2px 2px #00000030;
    background-color: white;
}
.files-grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}
.files-load-btn {
    width: 100%;
}
</style>