<template>
    <div>
        <div v-if="!this.isEditting && !this.id" class="add">
            <button @click="addHandler">Añadir categoría</button>
        </div>
        <div v-if="!this.isEditting && this.id" class="item">
            <p>{{ this.title }}</p>
            <div class="actions">
                <button @click="addHandler">Editar</button>
                <button @click="deleteHandler">Eliminar</button>
            </div>
        </div>
        <div v-if="this.isEditting" class="adding">
            <label for="title">Nombre de la categoría</label>
            <input type="text" id="title" v-model="newTitle">
            <label for="description">Descripción de la categoría</label>
            <textarea id="description" v-model="newDescription"></textarea>
            <button v-if="!this.id" @click="saveHandler">Guardar</button>
            <button v-if="this.id" @click="editHandler">Guardar</button>
            <button @click="cancelHandler">Cancelar</button>
        </div>
    </div>
</template>

<script>
export default {
    props: ['title', 'description', 'id'],
    data() {
        return {
            isEditting: false,
            newTitle: this.title,
            newDescription: this.description,
        };
    },
    methods: {
        addHandler() {
            this.isEditting = true;
        },
        cancelHandler() {
            this.newTitle = this.title;
            this.newDescription = this.description;
            this.isEditting = false;
        },
        saveHandler() {
            this.$emit('save', {
                title: this.newTitle,
                description: this.newDescription
            });
            this.newTitle = '';
            this.newDescription = '';
            this.isEditting = false;
        },
        editHandler() {
            this.$emit('edit', {
                title: this.newTitle,
                description: this.newDescription,
                id: this.id
            });
            this.newTitle = this.title;
            this.newDescription = this.description;
            this.isEditting = false;
        },
        deleteHandler() {
            this.$emit('delete', this.id);
        },
    }
};
</script>

<style lang="less">
@import '../../assets/css/blog/EditCategory.less';
</style>
