<template>
    <section>
        <Loader v-if="isLoading" />
        <template v-else>
            <ActionsBar @save="saveHandler" :save="true" :changes="hasChanges" v-if="!editing" />
            <div class="services">
                <div class="services__header">
                    <h3 class="services__header-title">Editor de Servicios</h3>
                    <p class="services__header-text">
                        En esta sección podrás <b>editar, crear y modificar</b> los servicios que aparecen en la página
                        principal.
                    </p>
                    <p class="services__header-text">
                        Cada servicio está compuesto de manera obligatoria por los siguientes campos:
                    </p>
                    <ul class="services__header-list">
                        <li>Imagenes: Una o varias imágenes</li>
                        <li>Título: El título que se muestra en el servicio</li>
                        <li>Texto: El texto adicional que se muestra justo debajo del título</li>
                    </ul>
                </div>

                <EditServices
                    @serviceData="handlerServiceData"
                    @serviceRemove="handlerRemoveService"
                    @editingEnable="editingHandler"
                    :serviceData="services"
                />
            </div>
        </template>
    </section>
</template>

<script>
import { getWebpageData, updateWebpageData } from '../services/firebase/webpageCRUD';

import { createToast } from '../utils/utils';

import Loader from '../components/generic/Loader.vue';
import ActionsBar from '../components/bars/ActionsBar.vue';

import EditServices from '../components/cms/EditServices.vue';
export default {
    components: {
        EditServices,
        ActionsBar,
        Loader,
    },
    data() {
        return {
            loading: false,
            changes: false,
            editing: false,
            services: [],
        };
    },
    methods: {
        // Handler to persist partnet info
        handlerServiceData(title, text, image, serviceIndex) {
            this.changes = true;

            if (this.services.some((service, index) => index === serviceIndex)) {
                this.services[serviceIndex].title = title;
                this.services[serviceIndex].text = text;
                this.services[serviceIndex].image = image;
                return;
            }

            this.services.push({
                title,
                text,
                image,
            });
        },

        // Hanlder to remove partner data
        handlerRemoveService(serviceIndex) {
            this.changes = true;
            this.services = this.services.filter((service, index) => index !== serviceIndex);
        },

        // Handler to save all data
        saveHandler() {
            updateWebpageData({
                services: this.services,
            })
                .then(({ msg }) => {
                    this.changes = false;
                    createToast({
                        text: msg,
                        type: 'success',
                        duration: 2500,
                    });
                })
                .catch(({ msg }) => {
                    createToast({
                        text: msg,
                        type: 'error',
                    });
                });
        },

        // Handler to fetch data
        fetchData() {
            this.loading = true;
            getWebpageData().then(({ webpageData }) => {
                const { services } = webpageData;
                this.services = services;
                this.loading = false;
            });
        },

        // Emit a boolean to hide Save button
        editingHandler(value) {
            this.editing = value;
        },
    },
    computed: {
        isLoading() {
            return this.loading;
        },

        hasChanges() {
            return this.changes;
        },
    },
    created() {
        this.fetchData();
    },
};
</script>

<style lang="less">
@import '../assets/css/viewsStyles/services.less';
</style>
