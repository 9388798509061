<template>
    <section>
        <Loader v-if="isLoading" />
        <template v-else>
            <ActionsBar @save="saveHandler" :changes="hasChanges" :save="true" />
            <div class="usersInvitation">
                <div class="usersInvitation__header">
                    <h3 class="usersInvitation__header-title">Invitaciones a usuarios</h3>
                    <p class="usersInvitation__header-text">
                        En esta sección podrás <b>crear una lista</b> de correos para invitarlos a la plataforma.
                    </p>
                    <p class="usersInvitation__header-text">
                        Una vez generada la lista y enviada, al usuario final le llega un correo con el
                        <b>enlace para entrar, su usuario y su contraseña</b>.
                    </p>
                </div>
            </div>
        </template>
        <section class="editUsersInvitation">
            <div>
                <p>Usuarios a los que se invitará:</p>
                <p v-for="(user, index) in users" :key="`user-${index}`">
                    <b>{{ user }}</b>
                </p>
            </div>
            <input type="mail" v-model="userCandidate" placeholder="Correo de usuario al que deseas invitar" />
            <p v-if="hasError">{{ error }}</p>
            <button @click="addMailHandler">Añadir</button>
        </section>
    </section>
</template>

<script>
import { emailExistsInDB } from '../services/firebase/auth';

import { createToast } from '../utils/utils';

import Loader from '../components/generic/Loader.vue';
import ActionsBar from '../components/bars/ActionsBar.vue';

export default {
    components: {
        ActionsBar,
        Loader,
    },
    data() {
        return {
            loading: false,
            changes: false,
            error: '',
            users: [],
            userCandidate: '',
        };
    },
    methods: {
        // Handler to save all data
        saveHandler() {
            fetch('https://us-central1-riverlion-4411c.cloudfunctions.net/createAccounts', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({users: this.users})
            })
                .then(res => {
                    this.changes = false;
                    res.json()
                    .then(res => {
                        createToast({
                            text: res.msg,
                            type: 'success',
                            duration: 2500,
                        });
                    })
                })
                .catch(() => {
                    createToast({
                        text: 'Ha habido un error',
                        type: 'error',
                    });
                });
        },

        addMailHandler() {
            emailExistsInDB(this.userCandidate)
                .then((hasExistInDB) => {
                    if (!hasExistInDB) {
                        this.users.push(this.userCandidate);
                        this.userCandidate = '';
                        this.changes = true;
                    }
                })
                .catch((error) => {
                    this.error = error;
                    setTimeout(() => {
                        this.error = '';
                    }, 2500);
                });
        },
    },
    computed: {
        isLoading() {
            return this.loading;
        },

        hasError() {
            return this.error.length;
        },

        hasChanges() {
            return this.changes;
        },
    },
};
</script>

<style lang="less">
@import '../assets/css/viewsStyles/users-invitation.less';
</style>
