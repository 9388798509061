import { firestore } from "./firebase";
import { productsCategoriesCRUDFeedbackStrings as feedback, throwCustomError } from './setup'

const updateAllAffectedProducts = async (oldCategoryName, newCategoryName) => {
    try {
        const querySnapshot = await firestore.collection('Products').where('category', '==', oldCategoryName).get();

        querySnapshot.forEach(async (doc) => {
            await firestore.collection('Products').doc(doc.id).update({category: newCategoryName || 'Sin categoría'});
        });
    } catch (err) {
        console.log(err);
        throw new Error(err);        
    }
}

const getOldCategoryData = async (categoryId) => {
    try {
        const fetchCategory = await firestore.collection('ProductsCategories').doc(categoryId).get();
        const categoryData = fetchCategory.data();
        return {
            title: categoryData.title,
            id: categoryId,
        }
    } catch (err) {
        console.log(err);
        throw new Error(err)
    }
}


//CHECKED
export const createNewProductsCategory = async (productCategory) => {
    try {
        await firestore.collection('ProductsCategories').add(productCategory);
        return {
            status: 200,
            msg: feedback.createNewProductsCategorySuccess,
        }
    } catch (err) {
        console.log(err)
        throwCustomError(feedback.createNewProductsCategoryFailure);
    }
}


//CHECKED
export const getAllProductsCategories = async () => {
    try {
        const querySnapshot = await firestore.collection('ProductsCategories').get();

        const productsCategoriesArray = [];
        querySnapshot.forEach(doc => {
            productsCategoriesArray.push({id: doc.id, ...doc.data()});
        });

        return {
            status: 200,
            msg: feedback.getAllProductsCategoriesSuccess,
            categories: productsCategoriesArray
        }
    } catch (err) {
        throwCustomError(feedback.getAllProductsCategoriesFailure);
    }
}


// CHECKED BASIC. PRODUCTS UPDATES LEFT
export const updateProductsCategory = async (productsCategory) => {
    try {
        await firestore.collection('ProductsCategories').doc(productsCategory.id).update({...productsCategory})

        const previousData = await getOldCategoryData(productsCategory.id);
        if (productsCategory.title !== previousData.title) {
            await updateAllAffectedProducts(previousData.title, productsCategory.title)
        }

        return {
            status: 200,
            msg: feedback.updateProductsCategorySuccess,
        }
    } catch (err) {
        console.log(err);
        throwCustomError(feedback.updateProductsCategoryFailure);
    }
}

// CHECKED BASIC. PRODUCTS UPDATES LEFT
export const deleteProductsCategoryById = async productsCategoryId => {
    try {

        const category = await getOldCategoryData(productsCategoryId);

        await updateAllAffectedProducts(category.title);
        await firestore.collection('ProductsCategories').doc(productsCategoryId).delete();

        return {
            status: 200,
            msg: feedback.deleteProductsCategoryByIdSuccess,
        }
    } catch (err) {
        console.log(err);
        throwCustomError(feedback.deletePostCategoryByIdFailure);
    }
}