<template>
    <section>
        <!-- <Loader v-if="isLoading" />
        <template v-else> -->
        <template>
            <div class="dashboard">
                <EditPost />
            </div>
        </template>
    </section>
</template>

<script>
// import { getAllPosts } from '../services/firebase/blogCRUD';

// import Loader from '../components/generic/Loader.vue';
import EditPost from '../components/blog/EditPost.vue';

export default {
    components: {
        // Loader,
        EditPost
    },
    data() {
        return {
            loading: false,
            postData: {}
        };
    },
    methods: {

        // fetchData() {
        //     this.loading = true;
        //     getAllPosts().then(({ posts }) => {
        //         this.postData = posts;
        //         this.loading = false;
        //     });
        // },
    },
    computed: {
        isLoading() {
            return this.loading;
        }
    },
    // created() {
    //     this.fetchData();
    // },
};
</script>

<style lang="less">
@import '../assets/css/viewsStyles/post.less';
</style>