import { firestore } from "./firebase";
import { productsCRUDFeedbackStrings as feedback, throwCustomError } from './setup';

export const createNewProduct = async (product) => {
    try {
        await firestore.collection('Products').add(product);
        return {
            status: 200,
            msg: feedback.createNewProductSuccess,
        }
    } catch (err) {
        console.log(err)
        throwCustomError(feedback.createNewProductFailure);
    }
}

export const getAllProducts = async () => {
    try {
        const querySnapshot = await firestore.collection('Products').get();

        const productsArray = [];
        querySnapshot.forEach(doc => {
            productsArray.push({id: doc.id, ...doc.data()});
        });

        return {
            status: 200,
            msg: feedback.getAllProductsSuccess,
            products: productsArray
        }
    } catch (err) {
        throwCustomError(feedback.getAllProductsFailure);
    }
}

export const getAllProductsByCategory = async (category) => {
    try {
        const querySnapshot = await firestore.collection('Products').where('categories', 'array-contains', category).get();

        const productsArray = [];
        querySnapshot.forEach(doc => {
            productsArray.push({id: doc.id, ...doc.data()});
        });

        return {
            status: 200,
            msg: feedback.getAllProductsByCategorySuccess,
            products: productsArray
        }
    } catch (err) {
        throwCustomError(feedback.getAllProductsByCategoryFailure);
    }
}

export const getProductById = async productId => {
    try {
        const doc = await firestore.collection('Products').doc(productId).get();
        if (doc.data()) {
            return {
                status: 200,
                msg: feedback.getProductByIdSuccess,
                product: {id: doc.id, ...doc.data()}
            }
        } else {
            throw new Error;
        }
    } catch (err) {
        throwCustomError(feedback.getProductByIdFailure);
    }
}

export const updateProduct = async (product) => {
    try {
        await firestore.collection('Products').doc(product.id).update({...product});
        const activeCollections = await firestore.collection('Collections').where('isActive', '==', true).get();
        activeCollections.forEach(async doc => {
            const collection = doc.data();
            const products = await firestore.collection('Collections').doc(collection.id).collection('Products').get();
            products.forEach(async doc => {
                const collectionProduct = doc.data();
                if(collectionProduct.id === product.id) {
                    await firestore.collection('Collections').doc(collection.id).collection('Products').doc(collectionProduct.id).update(product);
                }
            })
        });
        return {
            status: 200,
            msg: feedback.updateProductSuccess,
        }
    } catch (err) {
        console.log(err)
        throwCustomError(feedback.updateProductFailure);
    }
}

// TODO: Delete from collections (& votes??)
export const deleteProductById = async productId => {
    try {
        await firestore.collection('Products').doc(productId).delete();
        return {
            status: 200,
            msg: feedback.deleteProductByIdSuccess,
        }
    } catch (err) {
        throwCustomError(feedback.deleteProductByIdFailure);
    }
}
