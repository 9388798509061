<template>
    <section>
        <Loader v-if="isLoading" />
        <template v-else>
            <div class="collections-list" v-if="shouldShowCollections">
                <ListItem
                    v-for="closedCollection in closedCollections"
                    :key="closedCollection.id"
                    :collection="closedCollection"
                />
            </div>
            <div v-else>
                <h2>No hay ninguna colección registrada</h2>
            </div>
        </template>
    </section>
</template>

<script>
import { getAllFullCollections } from '../services/firebase/collectionsCRUD';

import { createToast } from '../utils/utils';

import Loader from '../components/generic/Loader.vue';

import ListItem from '../components/products/ListItem.vue';

export default {
    components: {
        ListItem,
        Loader,
    },
    name: 'Results',
    data() {
        return {
            loading: false,
            allCollections: [],
        };
    },
    methods: {
        fetchData() {
            this.loading = true;
            getAllFullCollections()
                .then(({ collections }) => {
                    this.allCollections = collections;
                    this.loading = false;
                })
                .catch(({ msg }) => {
                    createToast({
                        text: msg,
                        type: 'error',
                    });
                });
        },
    },
    computed: {
        isLoading() {
            return this.loading;
        },

        closedCollections() {
            const today = Date.now();
            return this.allCollections.filter((collection) => collection.endCollectionVoteDate.seconds * 1000 < today);
        },

        shouldShowCollections() {
            return this.closedCollections?.length;
        },
    },
    created() {
        this.fetchData();
    },
};
</script>

<style scoped>
.collections-list {
    padding: 1rem;
    width: 90%;
    margin: auto;
    box-shadow: 2px 2px 2px 2px #00000030;
    display: flex;
    flex-direction: column;
    background-color: white;
}
</style>
