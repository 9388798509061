<template>
  <div class="collections-users-list__row">
      <div class="collections-users-list__cell--header">{{ userName }}</div>
      <div class="collections-users-list__cell">{{ userVote.units }}</div>
      <div class="collections-users-list__cell">1</div>
      <div class="collections-users-list__cell">{{ userVote.stars }}</div>
  </div>
</template>

<script>
export default {
    props: {
        user: Object,
        product: Object
    },
    computed: {
        userName() {
            return this.user.name || 'Sin nombre asignado'
        },
        userVote() {
            return this.product.productVotes.find(product => product.user.id === this.user.id);
        }
    }

}
</script>

<style scoped>
.collections-users-list__row {
    display: flex;
    justify-content: space-between;
    align-content: center;
    cursor: pointer;
}
.collections-users-list__row:hover,
.collections-users-list__row:active {
    background-color: #bfd8f1;
    color: white;
    font-weight: bold;
}
.collections-users-list__cell--header {
    width: 45%;
    padding: 1rem;
    font-weight: 600;
}
.collections-users-list__cell {
    width: 20%;
    padding: 1rem;
}
</style>