<template>
    <section class="editSlider">
        <div v-if="!hasSlides && !isCreatingNewSlide" class="editSlider__no-sliders">
            <p class="editSlider__no-sliders-message">Actualmente no tienes ningún slider configurado.</p>
            <button class="editSlider__no-sliders-button" @click="createSlideHandler('new')">
                <FontAwesomeIcon class="editSlider__button-icon" icon="plus-circle" />Crear
            </button>
        </div>
        <div v-if="isCreatingNewSlide" class="editSlider__add-newslider">
            <section class="editSlider__add-newslider-header">
                <p class="editSlider__add-newslider-title">{{ titleText }}</p>
                <section class="editSlider__add-newslider-actions">
                    <button
                        @click="createSlideHandler('add')"
                        class="editSlider__add-newslider-button"
                        :class="mandatoryFields"
                    >
                        <FontAwesomeIcon class="actionBar__button-icon" :icon="saveButtonIcon" />{{ saveButtonText }}
                    </button>
                    <button @click="createSlideHandler('cancel')" class="editSlider__add-newslider-button">
                        Cancelar
                    </button>
                </section>
            </section>
            <div class="editSlider__add-newslider-form">
                <input type="title" v-model="title" placeholder="Escribe el título que aparecerá en el slider" />
                <input type="text" v-model="text" placeholder="Escribe el texto que aparecerá en el slider" />
                <FileUploader
                    :imagesData="image"
                    @uploadedImages="uploadedImagesHandler"
                    @editedImages="editedImagesHandler"
                />
            </div>
        </div>
        <div v-if="hasSlides && !isCreatingNewSlide" class="editSlider__wrapper">
            <button
                class="editSlider__no-sliders-button editSlider__no-sliders-button-custom"
                @click="createSlideHandler('new')"
            >
                <FontAwesomeIcon class="editSlider__button-icon" icon="plus-circle" />Añadir nuevo slider
            </button>
            <div v-for="({ image, title, text }, index) in sliderData" :key="index" class="editSlider__wrapper-items">
                <div class="editSlider__wrapper-items-actions">
                    <button
                        class="editSlider__no-sliders-button edit"
                        @click="actionsSlideHandler('edit', index, image, title, text)"
                    >
                        <FontAwesomeIcon class="editSlider__button-icon" icon="edit" />Editar
                    </button>
                    <button
                        class="editSlider__no-sliders-button remove"
                        @click="actionsSlideHandler('remove', index, image, title, text)"
                    >
                        <FontAwesomeIcon class="editSlider__button-icon" icon="eraser" />Eliminar
                    </button>
                </div>
                <div class="editSlider__wrapper-items-image">
                    <img :src="image[0]" />
                </div>
                <div class="editSlider__wrapper-items-section">
                    <h2 class="editSlider__wrapper-items-section-title">{{ title }}</h2>
                    <p class="editSlider__wrapper-items-section-text">Texto: {{ text }}</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faEraser, faPlusCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import FileUploader from '../generic/FileUploader.vue';

library.add(faEdit, faEraser, faPlusCircle, faSave);
export default {
    components: {
        FontAwesomeIcon,
        FileUploader,
    },
    props: {
        sliderData: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            isCreatingNewSlide: false,
            isEditing: false,
            title: '',
            text: '',
            image: [],
            index: null,
        };
    },
    methods: {
        // Handler to crear new slide
        createSlideHandler(method) {
            if (method === 'new') {
                this.$emit('editingEnable', true);
                this.isCreatingNewSlide = true;
                return;
            }

            if (method === 'cancel') {
                this.$emit('editingEnable', false);
                this.isCreatingNewSlide = false;
                this.reset();
                return;
            }

            if (method === 'add') {
                this.$emit('sliderData', this.title, this.text, this.image, this.index);
                this.isCreatingNewSlide = false;
                this.isEditing = false;
                this.$emit('editingEnable', false);
                this.reset();
                return;
            }
        },

        // Handler to edit or remove slide
        actionsSlideHandler(method, index, image, title, text) {
            if (method === 'edit') {
                this.image = image;
                this.title = title;
                this.text = text;
                this.index = index;
                this.isCreatingNewSlide = true;
                this.isEditing = true;
                this.$emit('editingEnable', true);
                return;
            }

            if (method === 'remove') {
                this.$emit('sliderRemove', index);
                return;
            }
        },

        // Reset all properties on slide
        reset() {
            this.title = '';
            this.text = '';
            this.image = [];
            this.index = null;
        },

        uploadedImagesHandler(image) {
            this.image = image;
        },

        editedImagesHandler(image) {
            this.image = image;
        },
    },
    computed: {
        // Check if exist data in slider
        hasSlides() {
            return !!this.sliderData?.length;
        },

        // Show text depends is editing or not
        saveButtonText() {
            return this.isEditing ? 'Guardar' : 'Añadir';
        },

        saveButtonIcon() {
            return this.isEditing ? 'save' : 'plus-circle';
        },

        titleText() {
            return this.isEditing ? 'Editando el slider' : 'Añadiendo nuevo slider';
        },

        mandatoryFields() {
            if (this.title.length && this.text.length && this.image.length) {
                return '';
            }
            return 'editSlider__add-newslider-button--not-allowed';
        },
    },
};
</script>

<style lang="less">
@import '../../assets/css/cms/edit-slider.less';
</style>
