import {v4 as uuidv4} from 'uuid';

import { firestore, storage } from './firebase';
import { filesCRUDFeedbackStrings as feedback, throwCustomError } from './setup';

export const createUniqueAliasForFile = (file) => {
    const uniqueKey = uuidv4();
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop();
    const fileAlias = (fileName.split('.')[0] + '-' + uniqueKey + '-' + fileExtension).replace(/\s/g,'-').toLowerCase();
    
    return fileAlias;
}

export const addFileTrackingInDB = async (fileData) => {
    try {
        await firestore.collection('Files').doc(fileData.id).set(fileData);
    } catch (err) {
        throw new Error;
    }
}

export const saveFileInStorage = async (input) => {
    try {
        const file = input
        const fileAlias = createUniqueAliasForFile(file);
        const uploadedFile = await storage.ref().child(fileAlias).put(file);
        const fileURL = await uploadedFile.ref.getDownloadURL();
        const fileExtension = () => {
            const utilityString = fileAlias.split('-');
            return utilityString[utilityString.length - 1]
        }
        const fileData = {
            id: fileAlias,
            type: fileExtension(),
            path: fileURL,
            uploadDate: new Date(),
            alt: input?.alt || fileAlias,
            title: input?.title || fileAlias
        }
        addFileTrackingInDB(fileData);

        return {
            status: 200,
            msg: feedback.saveFileInStorageSuccess,
            fileUrl: fileURL
        }
    } catch (err) {
        throwCustomError(feedback.saveFileInStorageFailure);
    }
}

export const getAllFiles = async () => {
    try {
        const allFiles = [];

        const fetchedUrls = await firestore.collection('Files').get();
        fetchedUrls.forEach(doc => {
            allFiles.push(doc.data());
        });

        return {
            status: 200,
            msg: feedback.getAllFilesSuccess,
            files: allFiles
        }
    } catch (err) {
        throwCustomError(feedback.getAllFilesFailure);
    }
}

export const getFileById = async (fileId) => {
    try {
        const fetchedFile = await firestore.collection('Files').doc(fileId).get();
        if(fetchedFile.data()) {
            return {
                status: 200,
                msg: feedback.getFileByIdSuccess,
                file: fetchedFile.data()
            }
        }
        throw new Error;
    } catch (err) {
        throwCustomError(feedback.getFileByIdFailure);
    }
}

export const deleteFileById = async (fileId) => {
    try {
        await storage.ref().child(fileId).delete();
        await firestore.collection('Files').doc(fileId).delete();
        return {
            status: 200,
            msg: feedback.deleteFileByFileNameSuccess,
        }
    } catch (err) {
        throwCustomError(feedback.deleteFileByFileNameFailure);
    }
}