<template>
    <section>
        <Loader v-if="isLoading" />
        <template v-else>
            <ActionsBar @save="saveHandler" :save="true" :changes="hasChanges" v-if="!editing" />
            <div class="productsCategories">
                <div class="productsCategories__header">
                    <h3 class="productsCategories__header-title">Editor de Categorías</h3>
                    <p class="productsCategories__header-text">
                        En esta sección podrás <b>editar, crear y modificar</b> las categorías que aparecen en la página
                        principal.
                    </p>
                    <p class="productsCategories__header-text">
                        Cada categoría está compuesto de manera obligatoria por los siguientes campos:
                    </p>
                    <ul class="productsCategories__header-list">
                        <li>Título: El título que se muestra en el categoría</li>
                    </ul>
                </div>

                <EditCategories
                    @categoriesData="handlerCategoryData"
                    @categoryRemove="handlerRemoveCategory"
                    @editingEnable="editingHandler"
                    :categoriesData="categories"
                />
            </div>
        </template>
    </section>
</template>

<script>
import {
    createNewProductsCategory,
    getAllProductsCategories,
    updateProductsCategory,
    deleteProductsCategoryById,
} from '../services/firebase/productsCategoriesCRUD';

import { createToast } from '../utils/utils';

import Loader from '../components/generic/Loader.vue';
import ActionsBar from '../components/bars/ActionsBar.vue';

import EditCategories from '../components/products/EditCategories.vue';
export default {
    components: {
        EditCategories,
        ActionsBar,
        Loader,
    },
    data() {
        return {
            loading: false,
            changes: false,
            editing: false,
            categories: [],
            categoryCandidate: {},
        };
    },
    methods: {
        // Handler to persist category info
        handlerCategoryData(title, categoryIndex) {
            this.changes = true;
            this.categoryCandidate = {
                title,
            };

            if (this.categories.some((category, index) => index === categoryIndex)) {
                this.updateCategoryHandler({ ...this.categoryCandidate, id: this.categories[categoryIndex].id });
                this.changes = false;
                return;
            }

            this.categories.push(this.categoryCandidate);
        },

        // Hanlder to remove category id
        handlerRemoveCategory(categoryId) {
            this.deleteHandler(categoryId);
        },

        // Handler to save all data
        saveHandler() {
            createNewProductsCategory(this.categoryCandidate)
                .then(({ msg, status }) => {
                    if (status === 200) {
                        this.changes = false;
                        this.categoryCandidate = '';

                        createToast({
                            text: msg,
                            type: 'success',
                            duration: 2500,
                        });
                    }
                })
                .catch(({ msg }) => {
                    createToast({
                        text: msg,
                        type: 'error',
                    });
                });
        },

        updateCategoryHandler(category) {
            try {
                updateProductsCategory(category)
                    .then(({ msg }) => {
                        createToast({
                            text: msg,
                            type: 'success',
                            duration: 2500,
                        });
                    })
                    .then(() => {
                        this.fetchData();
                        this.categoryCandidate = {};
                    });
            } catch ({ msg }) {
                createToast({
                    text: msg,
                    type: 'error',
                });
            }
        },

        // Handler to delete category by id
        deleteHandler(id) {
            try {
                deleteProductsCategoryById(id)
                    .then(({ msg }) => {
                        createToast({
                            text: msg,
                            type: 'success',
                            duration: 2500,
                        });
                    })
                    .then(() => {
                        this.fetchData();
                    });
            } catch ({ msg }) {
                createToast({
                    text: msg,
                    type: 'error',
                });
            }
        },

        // Handler to fetch data
        fetchData() {
            this.loading = true;
            getAllProductsCategories()
                .then(({ categories }) => {
                    this.categories = categories;
                    this.loading = false;
                })
                .catch(({ msg }) => {
                    createToast({
                        text: msg,
                        type: 'error',
                    });
                });
        },

        // Emit a boolean to hide Save button
        editingHandler(value) {
            this.editing = value;
        },
    },
    computed: {
        isLoading() {
            return this.loading;
        },

        hasChanges() {
            return this.changes;
        },
    },
    created() {
        this.fetchData();
    },
};
</script>

<style lang="less">
@import '../assets/css/viewsStyles/productsCategories.less';
</style>
