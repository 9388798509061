<template>
  <section>
      <Loader v-if="isLoading" />
        <template v-else>
            <div class="list-wrapper">
                <h2>Lista de artículos</h2>
                <ul class="items">
                    <BlogItem
                        v-for="post in postsList"
                        :key="post.id"
                        :post="post"
                        @updatePost="fetchData"
                    />
                </ul>
            </div>
        </template>
  </section>
</template>

<script>
import Loader from '../components/generic/Loader.vue';
import BlogItem from '../components/blog/BlogItem.vue';
import { getAllPosts } from '../services/firebase/blogCRUD';

export default {
    components: {
        Loader,
        BlogItem
    },
    data() {
        return {
            loading: false,
            postsList: [],
        };
    },
    methods: {
        fetchData() {
            this.loading = true;
            getAllPosts().then(({ posts }) => {
                this.postsList = posts;
                this.loading = false;
            });
        },
    },
    computed: {
        isLoading() {
            return this.loading;
        }
    },
    created() {
        this.fetchData();
    },
}
</script>

<style lang="less">
@import '../assets/css/viewsStyles/postsList.less';
</style>