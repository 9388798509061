<template>
  <div>
      <div
        class="collections-list__row"
        :class="index % 2 !== 0 ? 'collections-list__even' : ''"
        @click="toggleUserViewHandler"
    >
            <div class="collections-list__header-cell">{{ product.title }}</div>
            <div class="collections-list__cell">{{ product.categories }}</div>
            <div class="collections-list__cell">{{ totalOrderedUnits }}</div>
            <div class="collections-list__cell">{{ votesNumber}}</div>
            <div class="collections-list__cell">{{ averageScore}}</div>
    </div>
    <div v-if="viewingUser">
        <UserItem v-for="user in usersList" :key="user.id" :user="user" :product="product" />
    </div>
  </div>
</template>

<script>
import UserItem from './UserItem.vue';
export default {
    props: {
        product: Object,
        index: Number
    },
    components: {
        UserItem
    },
    data() {
        return {
            viewingUser: false
        }
    },
    computed: {
        averageScore() {
            const starArray = this.product.productVotes.map(vote => vote.stars);
            return (starArray.reduce((a, b) => a + b) / starArray.length).toFixed(2);
        },
        votesNumber() {
            return this.product.productVotes.length;
        },
        totalOrderedUnits() {
            const unitsArray = this.product.productVotes.map(vote => +vote.units);
            return unitsArray.reduce((a, b) => a + b);
        },
        usersList() {
            return this.product.productVotes.map(vote => vote.user);
        }
    },
    methods: {
        toggleUserViewHandler() {
            this.viewingUser = !this.viewingUser;
        }
    }

}
</script>

<style scoped>
.collections-list__row {
    display: flex;
    justify-content: space-between;
    align-content: center;
    cursor: pointer;
}
.collections-list__row:hover,
.collections-list__row:active {
    background-color: #708090;
    color: white;
    font-weight: bold;
}
.collections-list__even {
    background-color: lightgray;
}
.collections-list__header-cell {
    width: 20%;
    font-weight: 600;
    padding: 1rem;
}
.collections-list__cell {
    width: 20%;
    padding: 1rem;
}
</style>