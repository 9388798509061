<template>
    <section class="editCollections">
        <button
            v-if="hasCollections && !isCreatingNewCollection"
            class="editCollections__no-sliders-button"
            @click="createCollectionHandler('new')"
        >
            <FontAwesomeIcon class="editCollections__button-icon" icon="plus-circle" />Añadir nueva colección
        </button>
        <div v-if="!hasCollections && !isCreatingNewCollection" class="editCollections__no-collections">
            <p class="editCollections__no-collections-message">Actualmente no tienes ninguna colección.</p>
            <button class="editCollections__no-collections-button" @click="createCollectionHandler('new')">
                <FontAwesomeIcon class="editCollections__button-icon" icon="plus-circle" />Crear
            </button>
        </div>
        <div v-if="isCreatingNewCollection" class="editCollections__add-collection">
            <section class="editCollections__add-collection-header">
                <p class="editCollections__add-collection-header-title">{{ textCollection }}</p>
                <section class="editCollections__add-collection-header-buttons">
                    <button
                        @click="createCollectionHandler('add')"
                        class="editCollections__add-collection-button"
                        :class="mandatoryFields"
                    >
                        <FontAwesomeIcon class="editCollections__button-icon" :icon="saveButtonIcon" />{{
                            saveButtonText
                        }}
                    </button>
                    <button @click="createCollectionHandler('cancel')" class="editCollections__add-collection-button">
                        <FontAwesomeIcon class="editCollections__button-icon" icon="ban" />Cancelar
                    </button>
                </section>
            </section>
            <div class="editCollections__add-collection-form">
                <input type="title" v-model="title" placeholder="Escribe el título de la colección" />
                <p>Fecha de inicio de la colección</p>
                <input
                    type="date"
                    :value="startCollectionDate"
                    @change="startCollectionDateHandler"
                    placeholder="Fecha inicio de la colección"
                />
                <p>Fecha de fin de la colección</p>
                <input
                    type="date"
                    :value="endCollectionDate"
                    @change="endCollectionDateHandler"
                    placeholder="Fecha finalización de la colección"
                />
                <p>Fecha de inicio de la votación</p>
                <input
                    type="date"
                    :value="startCollectionVoteDate"
                    @change="startCollectionVoteDateHandler"
                    placeholder="Fecha inicio de la votación"
                />
                <p>Fecha de fin de la votación</p>
                <input
                    type="date"
                    :value="endCollectionVoteDate"
                    @change="endCollectionVoteDateHandler"
                    placeholder="Fecha finalización de la votación"
                />
                <div class="editCollections__add-collection-form">
                    <Loader v-if="isLoading" />
                    <div v-else>
                        <p class="editCollections__add-collection-form-text">
                            Productos seleccionados: <b>{{ selectedProducts }}</b>
                        </p>
                        <p v-if="!hasListProducts">No tienes ningún producto creado para seleccionar.</p>
                        <div class="editCollections__collection-select" v-else>
                            <div
                                v-for="{ categories, maxPrice, minPrice, image, title, text, id } in listProducts"
                                :key="`${id}-product`"
                            >
                                <div
                                    class="editCollections__wrapper-items-inner"
                                    @click="selectProductHandler(id)"
                                    :class="isSelectedProduct(id)"
                                >
                                    <FontAwesomeIcon
                                        class="editCollections__wrapper-items-inner-icon"
                                        icon="check-circle"
                                    />
                                    <section>
                                        <h2 class="editCollections__wrapper-items-title">{{ title }}</h2>
                                        <div class="editCollections__wrapper-items-section-text">
                                            <p>Precio mínimo: {{ minPrice }} €</p>
                                            <p>Precio máximo: {{ maxPrice }} €</p>
                                            <p>Categorías: {{ totalCategories(categories) }}</p>
                                        </div>
                                    </section>
                                    <div class="editCollections__wrapper-items-image">
                                        <img v-for="(img, index) in image" :src="img" :key="`${index}-image`" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="hasCollections && !isCreatingNewCollection" class="editCollections__wrapper">
            <div
                v-for="({
                    startCollectionDate,
                    endCollectionDate,
                    startCollectionVoteDate,
                    endCollectionVoteDate,
                    title,
                    products,
                    id,
                    isActive,
                },
                index) in collectionsData"
                :key="`${id}-collecion`"
                class="editCollections__wrapper-items"
            >
                <div class="editCollections__wrapper-items-actions">
                    <button
                        class="editCollections__no-collections-button edit"
                        @click="
                            actionsProductsHandler(
                                index,
                                startCollectionDate,
                                endCollectionDate,
                                startCollectionVoteDate,
                                endCollectionVoteDate,
                                title,
                                products,
                            )
                        "
                    >
                        <FontAwesomeIcon class="editCollections__button-icon" icon="edit" />Editar
                    </button>
                    <button class="editCollections__no-collections-button remove" @click="removeCollection(id)">
                        <FontAwesomeIcon class="editCollections__button-icon" icon="eraser" />Eliminar
                    </button>
                </div>
                <div class="editCollections__wrapper-items-inner">
                    <section>
                        <h2 class="editCollections__wrapper-items-title">{{ title }}</h2>
                        <p class="editCollections__wrapper-items-status">
                            Estado de la colección:
                            <span
                                class="editCollections__wrapper-items-status-text"
                                :class="collectionStatusClass(isActive)"
                                >{{ collectionStatus(isActive) }}</span
                            >
                        </p>
                        <div class="editCollections__wrapper-items-section-text">
                            <p>Fecha de inicio de la colección: {{ normalizeDate(startCollectionDate) }}</p>
                            <p>Fecha de fin de la colección: {{ normalizeDate(endCollectionDate) }}</p>
                            <p>Fecha de inicio de la votación: {{ normalizeDate(startCollectionVoteDate) }}</p>
                            <p>Fecha de fin de la votación: {{ normalizeDate(endCollectionVoteDate) }}</p>
                        </div>

                        <button class="editCollections__wrapper-show-more" @click="showMoreHandler">
                            {{ showMoreText }}
                        </button>
                    </section>
                    <section class="editCollections__wrapper-items-inner-products" v-if="showMore">
                        <div
                            v-for="{ categories, maxPrice, minPrice, image, title, text, id } in products"
                            :key="`${id}-product`"
                        >
                            <div class="editCollections__wrapper-items-inner">
                                <FontAwesomeIcon
                                    class="editCollections__wrapper-items-inner-icon"
                                    icon="check-circle"
                                />
                                <section>
                                    <h2 class="editCollections__wrapper-items-title">{{ title }}</h2>
                                    <div class="editCollections__wrapper-items-section-text">
                                        <p>Precio mínimo: {{ minPrice }} €</p>
                                        <p>Precio máximo: {{ maxPrice }} €</p>
                                        <p>Categorías: {{ totalCategories(categories) }}</p>
                                    </div>
                                </section>
                                <div class="editCollections__wrapper-items-image">
                                    <img v-for="(img, index) in image" :src="img" :key="`${index}-image`" />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { getAllProducts } from '../../services/firebase/productsCRUD';

import { createToast } from '../../utils/utils';

import Loader from '../generic/Loader.vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faEraser, faPlusCircle, faSave, faBan, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faEdit, faEraser, faPlusCircle, faSave, faBan, faCheckCircle);
export default {
    components: {
        FontAwesomeIcon,
        Loader,
    },
    props: {
        collectionsData: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            isCreatingNewCollection: false,
            isEditing: false,
            title: '',
            startCollectionDate: null,
            endCollectionDate: null,
            startCollectionVoteDate: null,
            endCollectionVoteDate: null,
            products: [],
            listProducts: [],
            index: null,
            loading: false,
            showMore: false,
        };
    },
    methods: {
        // Handler to crear new slide
        createCollectionHandler(method) {
            if (method === 'new') {
                this.$emit('editingEnable', true);
                this.isCreatingNewCollection = true;
                return;
            }

            if (method === 'cancel') {
                this.isCreatingNewCollection = false;
                this.$emit('editingEnable', false);
                this.reset();
                return;
            }

            if (method === 'add') {
                this.$emit(
                    'collectionsData',
                    this.title,
                    this.startCollectionDate,
                    this.endCollectionDate,
                    this.startCollectionVoteDate,
                    this.endCollectionVoteDate,
                    this.products,
                    this.index,
                );
                this.isCreatingNewCollection = false;
                this.isEditing = false;
                this.$emit('editingEnable', false);

                return;
            }
        },

        // Handler to edit or remove collection
        actionsProductsHandler(
            index,
            startCollectionDate,
            endCollectionDate,
            startCollectionVoteDate,
            endCollectionVoteDate,
            title,
            products,
        ) {
            this.title = title;
            this.startCollectionDate = this.setDate(startCollectionDate);
            this.index = index;
            this.endCollectionDate = this.setDate(endCollectionDate);
            this.startCollectionVoteDate = this.setDate(startCollectionVoteDate);
            this.endCollectionVoteDate = this.setDate(endCollectionVoteDate);
            this.products = products;
            this.isCreatingNewCollection = true;
            this.isEditing = true;
            this.$emit('editingEnable', true);
            return;
        },

        removeCollection(collectionId) {
            this.$emit('collectionRemove', collectionId);
        },

        // Reset all properties on slide
        reset() {
            this.title = '';
            this.startCollectionDate = null;
            this.endCollectionDate = null;
            this.startCollectionVoteDate = null;
            this.endCollectionVoteDate = null;
            this.index = null;
        },

        // Handler to fetch all products
        fetchProducts() {
            this.loading = true;
            getAllProducts()
                .then(({ products }) => {
                    this.listProducts = products;
                    this.loading = false;
                })
                .catch(({ msg }) => {
                    createToast({
                        text: msg,
                        type: 'error',
                    });
                });
        },

        totalCategories(category) {
            return category !== '' ? category : 'Sin categoría';
        },

        selectProductHandler(productId) {
            const product = this.listProducts.find(({ id }) => id === productId);

            if (this.products.some(({ id }) => id === productId)) {
                this.products = this.products.filter(({ id }) => id !== productId);
                return;
            }

            this.products.push(product);
        },

        isSelectedProduct(productId) {
            return this.products.some(({ id }) => id === productId)
                ? 'editCollections__wrapper-items-inner--selected'
                : '';
        },

        collectionStatus(status) {
            return status ? 'Activada' : 'Desactivada';
        },

        collectionStatusClass(status) {
            return status ? 'editCollections__wrapper-items-status-text--active' : '';
        },

        dateHandler(event, target) {
            const date = event.target.value;
            const dateObj = new Date(date);

            this[target] = dateObj;
        },

        normalizeDate({ seconds }) {
            const dateObj = new Date(seconds * 1000).toLocaleDateString();

            return dateObj;
        },

        setDate({ seconds }) {
            const dateObj = new Date(seconds * 1000);
            let day = dateObj.getDate();
            if (day < 10) {
                day = `0${day}`;
            }
            let month = dateObj.getMonth() + 1;
            if (month < 10) {
                month = `0${month}`;
            }
            const year = dateObj.getFullYear();
            return `${year}-${month}-${day}`;
        },
        startCollectionDateHandler(e) {
            this.startCollectionDate = e.target.value;
        },
        endCollectionDateHandler(e) {
            this.endCollectionDate = e.target.value;
        },
        startCollectionVoteDateHandler(e) {
            this.startCollectionVoteDate = e.target.value;
        },
        endCollectionVoteDateHandler(e) {
            this.endCollectionVoteDate = e.target.value;
        },

        showMoreHandler() {
            this.showMore = !this.showMore;
        },
    },
    computed: {
        // Check if exist data in slider
        hasCollections() {
            return !!this.collectionsData?.length;
        },

        // Show text depends is editing or not
        saveButtonText() {
            return this.isEditing ? 'Guardar' : 'Añadir';
        },

        mandatoryFields() {
            if (this.title?.length && this.almostNineProductSelecteds) {
                return '';
            }
            return 'editSlider__add-newslider-button--not-allowed';
        },

        saveButtonIcon() {
            return this.isEditing ? 'save' : 'plus-circle';
        },

        textCollection() {
            return this.isEditing ? 'Editando una colección' : 'Añadiendo nueva colleción';
        },

        isLoading() {
            return this.loading;
        },

        hasListProducts() {
            return this.listProducts.length;
        },

        selectedProducts() {
            return this.products.length;
        },

        almostNineProductSelecteds() {
            return this.products?.length === 9;
        },

        showMoreText() {
            return !this.showMore ? 'Ver más' : 'Ver menos';
        },
    },
    created() {
        this.fetchProducts();
    },
};
</script>

<style lang="less">
@import '../../assets/css/products/edit-collections.less';
</style>
