<template>
    <section class="editProyects">
        <button
            v-if="hasProyects && !isCreatingNewProyect"
            class="editProyects__no-sliders-button"
            @click="createProyectHandler('new')"
        >
            <FontAwesomeIcon class="editProyects__button-icon" icon="plus-circle" />Añadir nuevo proyecto
        </button>
        <div v-if="!hasProyects && !isCreatingNewProyect" class="editProyects__no-proyects">
            <p class="editProyects__no-proyects-message">Actualmente no tienes ningún proyecto.</p>
            <button class="editProyects__no-proyects-button" @click="createProyectHandler('new')">
                <FontAwesomeIcon class="editProyects__button-icon" icon="plus-circle" />Crear
            </button>
        </div>
        <div v-if="isCreatingNewProyect" class="editProyects__add-proyect">
            <section class="editProyects__add-proyect-header">
                <p class="editProyects__add-proyect-header-title">{{ textProject }}</p>
                <section class="editProyects__add-proyect-header-buttons">
                    <button
                        @click="createProyectHandler('add')"
                        class="editProyects__add-proyect-button"
                        :class="mandatoryFields"
                    >
                        <FontAwesomeIcon class="editProyects__button-icon" :icon="saveButtonIcon" />{{ saveButtonText }}
                    </button>
                    <button @click="createProyectHandler('cancel')" class="editProyects__add-proyect-button">
                        <FontAwesomeIcon class="editProyects__button-icon" icon="ban" />Cancelar
                    </button>
                </section>
            </section>
            <div class="editProyects__add-proyect-form">
                <input type="title" v-model="title" placeholder="Escribe el título del proyecto" />
                <VueEditor placeholder="Escribe sobre el proyecto..." v-model="text" />
                <FileUploader
                    :imagesData="image"
                    :multiple="true"
                    @uploadedImages="uploadedImagesHandler"
                    @editedImages="editedImagesHandler"
                />
                <h2 class="editProyects__wrapper-items-title">Galería:</h2>
                <FileUploader
                    :imagesData="gallery"
                    :multiple="true"
                    @uploadedImages="uploadedGalleryHandler"
                    @editedImages="editedGalleryHandler"
                />
            </div>
        </div>
        <div v-if="hasProyects && !isCreatingNewProyect" class="editProyects__wrapper">
            <div
                v-for="({ image, gallery, title, text }, index) in projectData"
                :key="index"
                class="editProyects__wrapper-items"
            >
                <div class="editProyects__wrapper-items-actions">
                    <button
                        class="editProyects__no-proyects-button edit"
                        @click="actionsProyectHandler('edit', index, image, gallery, title, text)"
                    >
                        <FontAwesomeIcon class="editProyects__button-icon" icon="edit" />Editar
                    </button>
                    <button
                        class="editProyects__no-proyects-button remove"
                        @click="actionsProyectHandler('remove', index, image, gallery, title, text)"
                    >
                        <FontAwesomeIcon class="editProyects__button-icon" icon="eraser" />Eliminar
                    </button>
                </div>
                <div class="editProyects__wrapper-items-inner">
                    <section>
                        <h2 class="editProyects__wrapper-items-title">{{ title }}</h2>
                        <p class="editProyects__wrapper-items-section-text" v-html="text"></p>
                    </section>
                    <div class="editProyects__wrapper-items-image">
                        <img v-for="img in image" :src="img" :key="img" />
                    </div>
                    <h2 class="editProyects__wrapper-items-title">Galería:</h2>
                    <div class="editProyects__wrapper-items-image">
                        <img v-for="img in gallery" :src="img" :key="img" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { VueEditor } from 'vue2-editor';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faEraser, faPlusCircle, faSave, faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import FileUploader from '../generic/FileUploader.vue';

library.add(faEdit, faEraser, faPlusCircle, faSave, faBan);
export default {
    components: {
        FontAwesomeIcon,
        FileUploader,
        VueEditor,
    },
    props: {
        projectData: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            isCreatingNewProyect: false,
            isEditing: false,
            title: '',
            text: '',
            image: [],
            gallery: [],
            index: null,
        };
    },
    methods: {
        // Handler to crear new slide
        createProyectHandler(method) {
            if (method === 'new') {
                this.$emit('editingEnable', true);
                this.isCreatingNewProyect = true;
                return;
            }

            if (method === 'cancel') {
                this.isCreatingNewProyect = false;
                this.$emit('editingEnable', false);
                return;
            }

            if (method === 'add') {
                this.$emit('projectData', this.title, this.text, this.image, this.gallery, this.index);
                this.isCreatingNewProyect = false;
                this.isEditing = false;
                this.$emit('editingEnable', false);
                this.reset();
                return;
            }
        },

        // Handler to edit or remove slide
        actionsProyectHandler(method, index, image, gallery, title, text) {
            if (method === 'edit') {
                this.title = title;
                this.text = text;
                this.image = image;
                this.gallery = gallery;
                this.index = index;
                this.isCreatingNewProyect = true;
                this.isEditing = true;
                this.$emit('editingEnable', true);
                return;
            }

            if (method === 'remove') {
                this.$emit('projectRemove', index);
                return;
            }
        },

        // Reset all properties on slide
        reset() {
            this.title = '';
            this.text = '';
            this.image = [];
            this.index = null;
        },

        uploadedImagesHandler(image) {
            this.image = image;
        },

        editedImagesHandler(image) {
            this.image = image;
        },

        uploadedGalleryHandler(gallery) {
            this.gallery = gallery;
        },

        editedGalleryHandler(gallery) {
            this.gallery = gallery;
        },
    },
    computed: {
        // Check if exist data in slider
        hasProyects() {
            return !!this.projectData?.length;
        },

        // Show text depends is editing or not
        saveButtonText() {
            return this.isEditing ? 'Guardar' : 'Añadir';
        },

        mandatoryFields() {
            if (this.title.length && this.text.length && this.image.length) {
                return '';
            }
            return 'editSlider__add-newslider-button--not-allowed';
        },

        saveButtonIcon() {
            return this.isEditing ? 'save' : 'plus-circle';
        },

        textProject() {
            return this.isEditing ? 'Editando un proyecto' : 'Añadiendo nuevo proyecto';
        },
    },
};
</script>

<style lang="less">
@import '../../assets/css/cms/edit-proyects.less';
</style>
