<template>
    <section class="fileUploader">
        <div class="file-input fileUploader__input" v-if="!hasUploadedFiles && !imagesToShow">
            <label class="form-label fileUploader__input-label">
                <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="cloud-upload-alt"
                    class="svg-inline--fa fa-cloud-upload-alt fa-w-20"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                >
                    <path
                        fill="currentColor"
                        d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zM393.4 288H328v112c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V288h-65.4c-14.3 0-21.4-17.2-11.3-27.3l105.4-105.4c6.2-6.2 16.4-6.2 22.6 0l105.4 105.4c10.1 10.1 2.9 27.3-11.3 27.3z"
                    ></path>
                </svg>
                <template v-if="multiple">
                    <input class="fileUploader__input-input" type="file" multiple @change="uploadFilesHandler" />
                </template>
                <template>
                    <input class="fileUploader__input-input" type="file" @change="uploadFilesHandler" />
                </template>
            </label>
            <p>Sube aquí tu imagen</p>
        </div>
        <Loader v-if="isLoading" />

        <button
            v-if="hasUploadedFiles && !isLoading"
            class="fileUploader__preview-images-button"
            @click="uploadImagesHandler"
        >
            <FontAwesomeIcon class="fileUploader__preview-images-button-icon" icon="cloud-upload-alt" />
            Subir imagenes
        </button>
        <div class="fileUploader__preview" v-if="imagesToShow && !isLoading">
            <section v-for="imageUrl in imagesToShow" class="fileUploader__preview-container" :key="imageUrl">
                <img class="fileUploader__preview-images" :src="imageUrl" />
                <FontAwesomeIcon
                    class="fileUploader__preview-images-remove"
                    icon="times-circle"
                    @click="removeImageHandler(imageUrl)"
                />
            </section>
        </div>
    </section>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimesCircle, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { createToast } from '../../utils/utils.js';
import Loader from '../generic/Loader.vue';

import { saveFileInStorage } from '../../services/firebase/filesCRUD';

library.add(faTimesCircle, faCloudUploadAlt);
export default {
    components: {
        FontAwesomeIcon,
        Loader,
    },
    props: {
        imagesData: {
            type: Array,
            default: () => [],
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            provisionalUrl: [],
            filesToUpload: [],
            definitiveUrl: [],
            editedImages: [],
        };
    },
    methods: {
        // Get input files and get provisional Url
        uploadFilesHandler(e) {
            const files = Array.from(e.target.files);

            files.forEach((file) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                    this.provisionalUrl = [...this.provisionalUrl, reader.result];
                    this.filesToUpload = [...this.filesToUpload, file];
                };
            });
        },

        // Remove image selected
        removeImageHandler(imageUrl) {
            this.editedImages = this.imagesToShow.filter((url) => url !== imageUrl);
            this.$emit('editedImages', this.editedImages);
        },

        // Reset preview and fileToUpload to inital state
        resetFileHandler() {
            this.previewUrl = [];
            this.filesToUpload = [];
        },

        // Upload images handler
        uploadImagesHandler() {
            this.filesToUpload.forEach((file) => {
                saveFileInStorage(file)
                    .then(({ status, fileUrl, msg }) => {
                        this.loading = true;

                        if (status === 200) {
                            this.definitiveUrl.push(fileUrl);
                            this.resetFileHandler();
                            this.loading = false;
                            this.$emit('uploadedImages', this.definitiveUrl);

                            createToast({
                                text: msg,
                                type: 'success',
                                duration: 2500,
                            });
                        }
                    })
                    .catch(({ msg }) => {
                        this.loading = false;

                        createToast({
                            text: msg,
                            type: 'error',
                        });
                    });
            });
        },
    },
    computed: {
        hasUploadedFiles() {
            return this.filesToUpload?.length;
        },

        isLoading() {
            return this.loading;
        },

        // Select images to show if server has images for this component or not
        imagesToShow() {
            if (this.imagesData?.length) {
                return this.imagesData;
            }

            if (this.provisionalUrl?.length) {
                return this.provisionalUrl;
            }

            return false;
        },
    },
};
</script>

<style lang="less">
@import '../../assets/css/generic/file-uploader.less';
</style>
