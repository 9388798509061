<template>
    <section>
        <Loader v-if="isLoading" />
        <template v-else>
            <div class="users-list">
                <ListItem
                    v-for="user in allUsers"
                    :key="user.id"
                    :user="user"
                    @deleteUser="deleteUserHandler"
                />
            </div>
            <div>
                <button @click="loadMoreHandler">Cargar más</button>
            </div>
        </template>
    </section>
</template>

<script>
import {
    filterAndFetch,
} from '../services/firebase/filter';

import { createToast } from '../utils/utils';

import Loader from '../components/generic/Loader.vue';

import ListItem from '../components/users/ListItem.vue';

export default {
    components: {
        ListItem,
        Loader
    },
    name: 'Users',
    data() {
        return {
            loading: false,
            allUsers: [],
            filterConfiguration: {
                collection: 'Users',
                criteria: 'id',
                order: 'asc',
                limit: 10,
            }
        };
    },
    methods: {
        deleteUserHandler(params) {
            fetch('https://us-central1-riverlion-4411c.cloudfunctions.net/deleteUser', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(params)
            })
                .then(res => {
                    return res.json();
                })
                .then(res => {
                    if (res.status === 200) {
                        createToast({
                            text: res.msg,
                            type: 'success',
                        });
                    } else {
                        const error = new Error;
                        error.msg = res.msg;
                        throw error;
                    }
                })
                .then(() => {
                    this.fetchData();
                })
                .catch(err => {
                    console.log(err);
                    createToast({
                        text: err.msg || err,
                        type: 'error',
                    });
                })
        },
        fetchData() {
            this.loading = true;
            filterAndFetch(this.filterConfiguration)
                .then(res => {
                    this.allUsers = res.results;
                    this.filterConfiguration.startAfter = res.startAfter;
                    this.loading = false;
                })
                .catch(({ msg }) => {
                    createToast({
                        text: msg,
                        type: 'error',
                    });
                });
        },
        loadMoreHandler() {
            this.loading = true;
            filterAndFetch(this.filterConfiguration)
                .then(res => {
                    this.allUsers.push(...res.results);
                    this.filterConfiguration.startAfter = res.startAfter;
                    this.loading = false;
                })
                .catch(({ msg }) => {
                    createToast({
                        text: msg,
                        type: 'error',
                    });
                });
        }
    },
    computed: {
        isLoading() {
            return this.loading;
        },
    },
    created() {
        this.fetchData();
    },
};
</script>

<style scoped>
.users-list {
    padding: 1rem;
    width: 90%;
    margin: auto;
    box-shadow: 2px 2px 2px 2px #00000030;
    display: flex;
    flex-direction: column;
    background-color: white;
}
</style>