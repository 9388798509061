<template>
    <section class="topBar">
        <div class="topBar__company">
            <p><router-link to="/" class="topBar__company-link">Panel de administración de: RIVERLION</router-link></p>
        </div>
        <div class="topBar__user">
            <FontAwesomeIcon icon="user" />
            <p class="topBar__user-name" @click="dropdownHandler">
                {{ user.name }}
                <FontAwesomeIcon :icon="userIcon" />
            </p>

            <div class="topBar__user-actions" v-if="showActionsMenu">
                <p class="topBar__user-actions-logout" @click="logoutHandler">Cerrar sesión</p>
            </div>
        </div>
    </section>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown, faAngleUp, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faAngleDown, faAngleUp, faUser);

export default {
    components: {
        FontAwesomeIcon,
    },
    props: {
        user: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            actionsMenu: false,
        };
    },
    methods: {
        logoutHandler() {
            this.$emit('logout');
        },

        dropdownHandler() {
            this.actionsMenu = !this.actionsMenu;
        },
    },

    computed: {
        showActionsMenu() {
            return this.actionsMenu;
        },

        userIcon() {
            return !this.actionsMenu ? 'angle-down' : 'angle-up';
        },
    },
};
</script>

<style lang="less">
@import '../../assets/css/bars/top-bar.less';
</style>
