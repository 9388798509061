<template>
    <section>
        <Loader v-if="isLoading" />
        <template v-else>
            <div class="products">
                <div class="products__header">
                    <h3 class="products__header-title">Editor de Colecciones</h3>
                    <p class="products__header-text">
                        En esta sección podrás <b>editar, crear y modificar</b> las colecciones que aparecen en la
                        página de votaciones.
                    </p>
                    <p class="products__header-text">
                        Cada colección está compuesto de manera obligatoria por los siguientes campos:
                    </p>
                    <ul class="products__header-list">
                        <li>Productos: 9 productos.</li>
                        <li>Título: El título que se muestra en la colección</li>
                        <li>Fecha de inicio: Fecha de inicio en la que se publicará</li>
                        <li>Fecha de fin: Fecha de fin en la que se publicará</li>
                        <li>Fecha de inicio de votación: Fecha de inicio en la que se se podrá votar</li>
                        <li>Fecha de fin de votación: Fecha hasta en la que se se podrá votar</li>
                    </ul>
                </div>
                <EditCollections
                    @collectionsData="handlerCollectionData"
                    @collectionRemove="handlerRemoveCollection"
                    @editingEnable="editingHandler"
                    :collectionsData="collections"
                />
            </div>
        </template>
    </section>
</template>

<script>
import {
    createNewCollectionAndGetId,
    addProductToCollection,
    getAllFullCollections,
    deleteCollectionById,
    updateCollection,
} from '../services/firebase/collectionsCRUD';

import { createToast } from '../utils/utils';

import Loader from '../components/generic/Loader.vue';

import EditCollections from '../components/products/EditCollections.vue';
export default {
    components: {
        EditCollections,
        Loader,
    },
    data() {
        return {
            loading: false,
            changes: false,
            editing: false,
            collections: [],
            collectionCandidate: {},
            productsCandidate: [],
        };
    },
    methods: {
        // Handler to persist collection info
        handlerCollectionData(
            title,
            startCollectionDate,
            endCollectionDate,
            startCollectionVoteDate,
            endCollectionVoteDate,
            products,
            collectionIndex,
        ) {
            this.collectionCandidate = {
                title,
                startCollectionDate: new Date(startCollectionDate),
                endCollectionDate: new Date(endCollectionDate),
                startCollectionVoteDate: new Date(startCollectionVoteDate),
                endCollectionVoteDate: new Date(endCollectionVoteDate),
            };

            this.productsCandidate = products;

            if (this.collections.some((collections, index) => index === collectionIndex)) {
                this.updateCollectionHandler({ ...this.collectionCandidate, id: this.collections[collectionIndex].id });
                this.changes = false;
                return;
            }

            this.saveHandler();
        },

        // Hanlder to remove collection id
        handlerRemoveCollection(collectionId) {
            this.deleteHandler(collectionId);
        },

        // Handler to save all data
        saveHandler() {
            try {
                createNewCollectionAndGetId(this.collectionCandidate).then(({ status, id }) => {
                    if (status === 200) {
                        this.changes = false;
                        this.collectionCandidate = {};

                        this.productsCandidate.forEach((product) => {
                            try {
                                addProductToCollection(product, id).then(({ msg, status }) => {
                                    if (status === 200) {
                                        createToast({
                                            text: msg,
                                            type: 'success',
                                            duration: 2500,
                                        });
                                    }
                                });
                            } catch ({ msg }) {
                                createToast({
                                    text: msg,
                                    type: 'error',
                                });
                            }
                        });

                        this.productsCandidate = [];
                        this.fetchData();
                    }
                });
            } catch ({ msg }) {
                createToast({
                    text: msg,
                    type: 'error',
                });
            }
        },

        // Handler to delete product by id
        deleteHandler(id) {
            try {
                deleteCollectionById(id)
                    .then(({ msg }) => {
                        createToast({
                            text: msg,
                            type: 'success',
                            duration: 2500,
                        });
                    })
                    .then(() => {
                        this.fetchData();
                    });
            } catch ({ msg }) {
                createToast({
                    text: msg,
                    type: 'error',
                });
            }
        },

        updateCollectionHandler(collection) {
            console.log(collection);
            try {
                updateCollection(collection)
                    .then(({ msg }) => {
                        createToast({
                            text: msg,
                            type: 'success',
                            duration: 2500,
                        });
                    })
                    .then(() => {
                        this.fetchData();
                        this.collectionCandidate = {};
                    });
            } catch ({ msg }) {
                createToast({
                    text: msg,
                    type: 'error',
                });
            }
        },

        // Handler to fetch data
        fetchData() {
            this.loading = true;
            getAllFullCollections()
                .then(({ collections }) => {
                    this.collections = collections;
                    this.loading = false;
                })
                .catch(({ msg }) => {
                    createToast({
                        text: msg,
                        type: 'error',
                    });
                });
        },

        // Emit a boolean to hide Save button
        editingHandler(value) {
            this.editing = value;
        },
    },
    computed: {
        isLoading() {
            return this.loading;
        },

        hasChanges() {
            return this.changes;
        },
    },
    created() {
        this.fetchData();
    },
};
</script>

<style lang="less">
@import '../assets/css/viewsStyles/collections.less';
</style>
