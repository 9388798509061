<template>
    <section>
        <Loader v-if="isLoading" />
        <template v-else>
            <ActionsBar @save="saveHandler" :save="true" :changes="hasChanges" v-if="!editing" />
            <div class="slider">
                <div class="slider__header">
                    <h3 class="slider__header-title">Editor de Sliders</h3>
                    <p class="slider__header-text">
                        En esta sección podrás <b>editar, crear y modificar</b> los sliders que aparecen en la página
                        principal.
                    </p>
                    <p class="slider__header-text">
                        Cada slider está compuesto de manera obligatoria por los siguientes campos:
                    </p>
                    <ul class="slider__header-list">
                        <li>Imagen: Un único fichero</li>
                        <li>Título: El título que se muestra en el slider</li>
                        <li>Texto: El texto adicional que se muestra justo debajo del título</li>
                    </ul>
                </div>

                <EditSlider
                    @sliderData="handlerSliderData"
                    @sliderRemove="handlerRemoveSlide"
                    @editingEnable="editingHandler"
                    :sliderData="slider"
                />
            </div>
        </template>
    </section>
</template>

<script>
import { getWebpageData, updateWebpageData } from '../services/firebase/webpageCRUD';

import { createToast } from '../utils/utils';

import Loader from '../components/generic/Loader.vue';
import ActionsBar from '../components/bars/ActionsBar.vue';

import EditSlider from '../components/cms/EditSlider.vue';
export default {
    components: {
        EditSlider,
        ActionsBar,
        Loader,
    },
    data() {
        return {
            loading: false,
            changes: false,
            editing: false,
            slider: [],
        };
    },
    methods: {
        // Handler to persist slide info
        handlerSliderData(title, text, image, slideIndex) {
            this.changes = true;

            if (this.slider.some((slide, index) => index === slideIndex)) {
                this.slider[slideIndex].title = title;
                this.slider[slideIndex].text = text;
                this.slider[slideIndex].image = image;
                return;
            }

            this.slider.push({
                title,
                text,
                image,
            });
        },

        // Hanlder to remove slide data
        handlerRemoveSlide(slideIndex) {
            this.changes = true;
            this.slider = this.slider.filter((slide, index) => index !== slideIndex);
        },

        // Handler to save all data
        saveHandler() {
            updateWebpageData({
                slides: this.slider,
            })
                .then(({ msg }) => {
                    this.changes = false;
                    createToast({
                        text: msg,
                        type: 'success',
                        duration: 2500,
                    });
                })
                .catch(({ msg }) => {
                    createToast({
                        text: msg,
                        type: 'error',
                    });
                });
        },

        // Handler to fetch data
        fetchData() {
            this.loading = true;
            getWebpageData().then(({ webpageData }) => {
                const { slides } = webpageData;
                this.slider = slides;
                this.loading = false;
            });
        },

        // Emit a boolean to hide Save button
        editingHandler(value) {
            this.editing = value;
        },
    },
    computed: {
        isLoading() {
            return this.loading;
        },

        hasChanges() {
            return this.changes;
        },
    },
    created() {
        this.fetchData();
    },
};
</script>

<style lang="less">
@import '../assets/css/viewsStyles/slider.less';
</style>
