<template>
    <div class="login">
        <div class="login__form">
            <img class="login__form-logo" src="../../assets/img/logo.png" />

            <template v-if="!isForgottenPassword">
                <p class="login__form-text">Introduce tus datos para conectarte</p>
                <input
                    class="login__form-form"
                    type="email"
                    placeholder="Introduce el correo electrónico"
                    v-model="email"
                />
                <input
                    class="login__form-form"
                    type="password"
                    placeholder="Introduce la contraseña"
                    v-model="password"
                />
                <p class="login__form-forgotten" @click="passwordRecoveryHandler">He olvidado la contraseña</p>
                <button class="login__form-button" @click="loginHandler" :disabled="!mandatoryFields">Entrar</button>
            </template>

            <template v-if="isForgottenPassword">
                <p class="login__form-text">Escribe tu correo electrónico para recuperar la contraseña</p>
                <input
                    class="login__form-form"
                    type="email"
                    placeholder="Introduce el correo electrónico"
                    v-model="passwordRecovery"
                />
                <p class="login__form-forgotten" @click="backToLoginHandler">Volver atrás</p>
                <button class="login__form-button" @click="passwordRecoveryHandler" :disabled="!mandatoryFields">
                    Recuperar
                </button>
            </template>
        </div>
    </div>
</template>

<script>
import { loginWithEmailAndPassword, recoverPasswordByEmail } from '../../services/firebase/auth';
import { createToast } from '../../utils/utils';

export default {
    data() {
        return {
            email: '',
            password: '',
            passwordRecovery: '',
            forgottenPassword: false,
        };
    },
    methods: {
        // Handler to request a login
        loginHandler() {
            loginWithEmailAndPassword(this.email, this.password)
                .then(({ status, msg }) => {
                    if (status === 200) {
                        this.$emit('setLoggedInUserData');

                        createToast({
                            text: msg,
                            type: 'success',
                            duration: 2500,
                        });
                    }
                })
                .catch(({ msg }) => {
                    createToast({
                        text: msg,
                        type: 'error',
                    });
                });
        },

        // Handler to request a password recovery
        passwordRecoveryHandler() {
            if (!this.isForgottenPassword) {
                this.forgottenPassword = true;
                return;
            }
            recoverPasswordByEmail(this.passwordRecovery)
                .then(({ msg }) => {
                    this.forgottenPassword = false;
                    createToast({
                        text: msg,
                        type: 'success',
                        duration: 2500,
                    });
                })
                .catch(({ msg }) => {
                    createToast({
                        text: msg,
                        type: 'error',
                    });
                });
        },

        // Handler to login form
        backToLoginHandler() {
            this.forgottenPassword = false;
        },
    },
    computed: {
        isForgottenPassword() {
            return this.forgottenPassword;
        },

        mandatoryFields() {
            if (this.isForgottenPassword) {
                return !!this.passwordRecovery.length;
            }
            return !!this.email.length && !!this.password.length;
        },
    },
};
</script>

<style lang="less">
@import '../../assets/css/forms/login.less';
</style>
