<template>
    <section class="editProducts">
        <button
            v-if="hasProducts && !isCreatingNewProduct"
            class="editProducts__no-sliders-button"
            @click="createProductHandler('new')"
        >
            <FontAwesomeIcon class="editProducts__button-icon" icon="plus-circle" />Añadir nuevo producto
        </button>
        <div v-if="!hasProducts && !isCreatingNewProduct" class="editProducts__no-proyects">
            <p class="editProducts__no-proyects-message">Actualmente no tienes ningún producto.</p>
            <button class="editProducts__no-proyects-button" @click="createProductHandler('new')">
                <FontAwesomeIcon class="editProducts__button-icon" icon="plus-circle" />Crear
            </button>
        </div>
        <div v-if="isCreatingNewProduct" class="editProducts__add-proyect">
            <section class="editProducts__add-proyect-header">
                <p class="editProducts__add-proyect-header-title">{{ textProject }}</p>
                <section class="editProducts__add-proyect-header-buttons">
                    <button
                        @click="createProductHandler('add')"
                        class="editProducts__add-proyect-button"
                        :class="mandatoryFields"
                    >
                        <FontAwesomeIcon class="editProducts__button-icon" :icon="saveButtonIcon" />{{ saveButtonText }}
                    </button>
                    <button @click="createProductHandler('cancel')" class="editProducts__add-proyect-button">
                        <FontAwesomeIcon class="editProducts__button-icon" icon="ban" />Cancelar
                    </button>
                </section>
            </section>
            <div class="editProducts__add-proyect-form">
                <input type="title" v-model="title" placeholder="Escribe el nombre del producto" />
                <input type="number" v-model="minPrice" placeholder="Precio mínimo" />
                <input type="number" v-model="maxPrice" placeholder="Precio máximo" /> <br />
                <select name="categories" id="categories" v-model="categories" placeholder="Selecciona una categoría">
                    <option v-for="{ id, title } in categoriesData" :key="id" :value="title">{{ title }}</option>
                </select>
                <FileUploader
                    :imagesData="image"
                    @uploadedImages="uploadedImagesHandler"
                    @editedImages="editedImagesHandler"
                />
            </div>
        </div>
        <div v-if="hasProducts && !isCreatingNewProduct" class="editProducts__wrapper">
            <div
                v-for="({ categories, maxPrice, minPrice, image, title, text, id }, index) in productData"
                :key="`${id}-product`"
                class="editProducts__wrapper-items"
            >
                <div class="editProducts__wrapper-items-actions">
                    <button
                        class="editProducts__no-proyects-button edit"
                        @click="actionsProductsHandler(index, categories, maxPrice, minPrice, image, title, text, id)"
                    >
                        <FontAwesomeIcon class="editProducts__button-icon" icon="edit" />Editar
                    </button>
                    <button class="editProducts__no-proyects-button remove" @click="removeProduct(id)">
                        <FontAwesomeIcon class="editProducts__button-icon" icon="eraser" />Eliminar
                    </button>
                </div>
                <div class="editProducts__wrapper-items-inner">
                    <section>
                        <h2 class="editProducts__wrapper-items-title">{{ title }}</h2>
                        <div class="editProducts__wrapper-items-section-text">
                            <p>Precio mínimo: {{ minPrice }} €</p>
                            <p>Precio máximo: {{ maxPrice }} €</p>
                            <p>Categorías: {{ totalCategories(categories) }}</p>
                        </div>
                    </section>
                    <div class="editProducts__wrapper-items-image">
                        <img v-for="(img, index) in image" :src="img" :key="`${index}-image`" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faEraser, faPlusCircle, faSave, faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import FileUploader from '../generic/FileUploader.vue';

library.add(faEdit, faEraser, faPlusCircle, faSave, faBan);
export default {
    components: {
        FontAwesomeIcon,
        FileUploader,
    },
    props: {
        productData: {
            type: Array,
            default: () => [],
        },
        categoriesData: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            isCreatingNewProduct: false,
            isEditing: false,
            title: '',
            image: [],
            categories: '',
            minPrice: '',
            maxPrice: '',
            index: null,
        };
    },
    methods: {
        // Handler to crear new slide
        createProductHandler(method) {
            if (method === 'new') {
                this.$emit('editingEnable', true);
                this.isCreatingNewProduct = true;
                return;
            }

            if (method === 'cancel') {
                this.isCreatingNewProduct = false;
                this.$emit('editingEnable', false);
                this.reset();
                return;
            }

            if (method === 'add') {
                this.$emit(
                    'productData',
                    this.title,
                    this.image,
                    this.index,
                    this.categories,
                    this.minPrice,
                    this.maxPrice,
                );
                this.isCreatingNewProduct = false;
                this.isEditing = false;
                this.$emit('editingEnable', false);
                this.reset();

                return;
            }
        },

        // Handler to edit or remove slide
        actionsProductsHandler(index, categories, maxPrice, minPrice, image, title) {
            this.title = title;
            this.image = image;
            this.index = index;
            this.categories = categories;
            this.minPrice = minPrice;
            this.maxPrice = maxPrice;
            this.isCreatingNewProduct = true;
            this.isEditing = true;
            this.$emit('editingEnable', true);
            return;
        },

        removeProduct(productId) {
            this.$emit('productRemove', productId);
        },

        // Reset all properties on slide
        reset() {
            this.title = '';
            this.image = [];
            this.categories = '';
            this.minPrice = '';
            this.maxPrice = '';
            this.index = null;
        },

        uploadedImagesHandler(image) {
            this.image = image;
        },

        editedImagesHandler(image) {
            this.image = image;
        },

        totalCategories(category) {
            return category !== '' ? category : 'Sin categoría';
        },
    },
    computed: {
        // Check if exist data in slider
        hasProducts() {
            return !!this.productData?.length;
        },

        // Show text depends is editing or not
        saveButtonText() {
            return this.isEditing ? 'Guardar' : 'Añadir';
        },

        mandatoryFields() {
            if (this.title?.length && this.image?.length) {
                return '';
            }
            return 'editSlider__add-newslider-button--not-allowed';
        },

        saveButtonIcon() {
            return this.isEditing ? 'save' : 'plus-circle';
        },

        textProject() {
            return this.isEditing ? 'Editando un producto' : 'Añadiendo nuevo producto';
        },
    },
};
</script>

<style lang="less">
@import '../../assets/css/products/edit-products.less';
</style>
