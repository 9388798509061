import { firestore } from "./firebase";
import { blogCRUDFeedbackStrings as feedback, throwCustomError } from './setup'

export const createNewPost = async (post) => {
    try {
        await firestore.collection('Posts').add(post);
        return {
            status: 200,
            msg: feedback.createNewPostSuccess,
        }
    } catch (err) {
        console.log(err)
        throwCustomError(feedback.createNewPostFailure);
    }
}

export const getAllPosts = async () => {
    try {
        const querySnapshot = await firestore.collection('Posts').get();

        const postsArray = [];
        querySnapshot.forEach(doc => {
            postsArray.push({id: doc.id, ...doc.data()});
        });

        return {
            status: 200,
            msg: feedback.getAllPostsSuccess,
            posts: postsArray
        }
    } catch (err) {
        throwCustomError(feedback.getAllPostsFailure);
    }
}

export const getAllPostsByCategory = async (category) => {
    try {
        const querySnapshot = await firestore.collection('Posts').where('category', '==', category).get();

        const postsArray = [];
        querySnapshot.forEach(doc => {
            postsArray.push({id: doc.id, ...doc.data()});
        });

        return {
            status: 200,
            msg: feedback.getAllPostsByCategorySuccess,
            posts: postsArray
        }
    } catch (err) {
        throwCustomError(feedback.getAllPostsByCategoryFailure);
    }
}

export const getPostById = async postId => {
    try {
        const doc = await firestore.collection('Posts').doc(postId).get();
        if (doc.data()) {
            return {
                status: 200,
                msg: feedback.getPostByIdSuccess,
                post: {id: doc.id, ...doc.data()}
            }
        } else {
            throw new Error;
        }
    } catch (err) {
        throwCustomError(feedback.getPostByIdFailure);
    }
}

export const updatePost = async (post) => {
    try {
        await firestore.collection('Posts').doc(post.id).update({...post})
        return {
            status: 200,
            msg: feedback.updatePostSuccess,
        }
    } catch (err) {
        console.log(err)
        throwCustomError(feedback.updatePostFailure);
    }
}

export const deletePostById = async postId => {
    try {
        await firestore.collection('Posts').doc(postId).delete();
        return {
            status: 200,
            msg: feedback.deletePostByIdSuccess,
        }
    } catch (err) {
        throwCustomError(feedback.deletePostByIdFailure);
    }
}