<template>
    <section class="editPartners">
        <button
            v-if="hasPartners && !isCreatingNewPartner"
            class="editPartners__no-partners-button"
            @click="createPartnerHandler('new')"
        >
            <FontAwesomeIcon class="editPartners__button-icon" icon="plus-circle" />Añadir nuevo partner
        </button>
        <div v-if="!hasPartners && !isCreatingNewPartner" class="editPartners__no-partners">
            <p class="editPartners__no-partners-message">Actualmente no tienes ningún partner</p>
            <button class="editPartners__no-partners-button" @click="createPartnerHandler('new')">
                <FontAwesomeIcon class="editPartners__button-icon" icon="plus-circle" />Añadir
            </button>
        </div>
        <div v-if="isCreatingNewPartner" class="editPartners__add-newpartner">
            <p class="editPartners__add-newpartner-title">{{ textPartner }}</p>
            <div class="editPartners__add-newpartner-form">
                <input type="src" v-model="url" placeholder="Escribe el enlace de destino" />
                <FileUploader
                    :imagesData="image"
                    @uploadedImages="uploadedImagesHandler"
                    @editedImages="editedImagesHandler"
                />
            </div>
            <section class="editPartners__add-newpartner-actions">
                <button
                    @click="createPartnerHandler('add')"
                    class="editPartners__add-newpartner-button"
                    :class="mandatoryFields"
                >
                    <FontAwesomeIcon class="editPartners__button-icon" :icon="saveButtonIcon" />{{ saveButtonText }}
                </button>
                <button @click="createPartnerHandler('cancel')" class="editPartners__add-newpartner-button">
                    <FontAwesomeIcon class="editPartners__button-icon" icon="ban" />Cancelar
                </button>
            </section>
        </div>
        <div v-if="hasPartners && !isCreatingNewPartner" class="editPartners__wrapper">
            <div v-for="({ image, url }, index) in partnerData" :key="index" class="editPartners__wrapper-items">
                <div class="editPartners__wrapper-items-image">
                    <a :href="url" target="_blank">
                        <img :src="image" />
                    </a>
                </div>
                <div class="editPartners__wrapper-items-actions">
                    <button
                        class="editPartners__no-partners-button edit"
                        @click="actionsPartnerHandler('edit', index, image, url)"
                    >
                        <FontAwesomeIcon class="editPartners__button-icon" icon="edit" />Editar
                    </button>
                    <button
                        class="editPartners__no-partners-button remove"
                        @click="actionsPartnerHandler('remove', index, image, url)"
                    >
                        <FontAwesomeIcon class="editPartners__button-icon" icon="eraser" />Eliminar
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faEraser, faPlusCircle, faSave, faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import FileUploader from '../generic/FileUploader.vue';

library.add(faEdit, faEraser, faPlusCircle, faSave, faBan);
export default {
    components: {
        FontAwesomeIcon,
        FileUploader,
    },
    props: {
        partnerData: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            isCreatingNewPartner: false,
            isEditing: false,
            index: null,
            image: [],
            url: '',
        };
    },
    methods: {
        createPartnerHandler(method) {
            if (method === 'new') {
                this.$emit('editingEnable', true);
                this.isCreatingNewPartner = true;
                return;
            }

            if (method === 'cancel') {
                this.isCreatingNewPartner = false;
                this.$emit('editingEnable', false);
                this.reset();
                return;
            }

            if (method === 'add') {
                this.$emit('partnerData', this.image, this.url, this.index);
                this.isCreatingNewPartner = false;
                this.isEditing = false;
                this.$emit('editingEnable', false);
                this.reset();
                return;
            }
        },

        // Handler to edit or remove slide
        actionsPartnerHandler(method, index, image, url) {
            if (method === 'edit') {
                this.image = image;
                this.url = url;
                this.index = index;
                this.isCreatingNewPartner = true;
                this.isEditing = true;
                this.$emit('editingEnable', true);
                return;
            }

            if (method === 'remove') {
                this.$emit('partnerRemove', index);
                return;
            }
        },

        // Reset all properties on slide
        reset() {
            this.url = '';
            this.image = [];
            this.index = null;
        },

        uploadedImagesHandler(image) {
            this.image = image;
        },

        editedImagesHandler(image) {
            this.image = image;
        },
    },
    computed: {
        // Check if exist data in slider
        hasPartners() {
            return !!this.partnerData?.length;
        },

        // Show text depends is editing or not
        saveButtonText() {
            return this.isEditing ? 'Guardar' : 'Añadir';
        },

        textPartner() {
            return this.isEditing ? 'Editando partner' : 'Añadiendo nuevo partner';
        },

        mandatoryFields() {
            if (this.image.length && this.url.length) {
                return '';
            }
            return 'editPartners__add-newpartner-button--not-allowed';
        },

        saveButtonIcon() {
            return this.isEditing ? 'save' : 'plus-circle';
        },
    },
};
</script>

<style lang="less">
@import '../../assets/css/cms/edit-partners.less';
</style>
