export const webPageId = 'WEBPAGE';

export const throwCustomError = (msg) => {
    const error = new Error;
    error.msg = msg;
    throw error;
}

export const authFeedbackStrings = {
    loginWithEmailAndPasswordSuccess: 'Has iniciado sesión',
    loginWithEmailAndPasswordFailure: 'Error al iniciar sesión',

    changePasswordSuccess: 'Has cambiado tu contraseña',
    changePasswordFailure: 'Error. Cierra sesión y vuelve a entrar e intentarlo',

    logoutSuccess: 'Has cerrado tu sesión',
    logoutFailure: 'Error al cerrar sesión',

    getCurrentUserDataSuccess: 'Datos recuperados',
    getCurrentUserDataFailure: 'Error al recuperar los datos del usuario',

    recoverPasswordByEmail: 'Si el correo es correcto te habremos mandado un email de recuperación. Comprueba el spam. Si no aparece, contacta con administración'
};

export const blogCategoriesCRUDFeedbackStrings = {
    createNewPostCategorySuccess: 'Has creado una nueva categoría',
    createNewPostCategoryFailure: 'Error al guardar la categoría',

    getAllPostsCategoriesSuccess: 'Categorías cargadas',
    getAllPostsCategoriesFailure: 'Error al recuperar las categorías',

    updatePostCategorySuccess: 'Has actualizado la categoría',
    updatePostCategoryFailure: 'Error al actualizar la categoría',

    deletePostCategoryByIdSuccess: 'Has eliminado la categoría',
    deletePostCategoryByIdFailure: 'Error al eliminar la categoría'
};

export const productsCategoriesCRUDFeedbackStrings = {
    createNewProductsCategorySuccess: 'Has creado una nueva categoría',
    createNewProductsCategoryFailure: 'Error al guardar la categoría',

    getAllProductsCategoriesSuccess: 'Categorías cargadas',
    getAllProductsCategoriesFailure: 'Error al recuperar las categorías',

    updateProductsCategorySuccess: 'Has actualizado la categoría',
    updateProductsCategoryFailure: 'Error al actualizar la categoría',

    deleteProductsCategoryByIdSuccess: 'Has eliminado la categoría',
    deletePostCategoryByIdFailure: 'Error al eliminar la categoría'
};

export const blogCRUDFeedbackStrings = {
    createNewPostSuccess: 'Has publicado un nuevo post',
    createNewPostFailure: 'Error al guardar el post',

    getAllPostsSuccess: 'Posts cargados',
    getAllPostsFailure: 'Error al recuperar los posts',

    getAllPostsByCategorySuccess: 'Posts cargados',
    getAllPostsByCategoryFailure: 'Error al recuperar los posts',

    getPostByIdSuccess: 'Post cargado',
    getPostByIdFailure: 'Error al recuperar el post',

    updatePostSuccess: 'Has actualizado tu post',
    updatePostFailure: 'Error al actualizar el post',

    deletePostByIdSuccess: 'Has eliminado tu post',
    deletePostByIdFailure: 'Error al eliminar el post'
};

export const productsCRUDFeedbackStrings = {
    createNewProductSuccess: 'Has creado un nuevo producto',
    createNewProductFailure: 'Error al guardar el producto',

    getAllProductsSuccess: 'Productos cargados',
    getAllProductsFailure: 'Error al recuperar los productos',

    getAllProductsByCategorySuccess: 'Productos cargados',
    getAllProductsByCategoryFailure: 'Error al recuperar los productos',

    getProductByIdSuccess: 'Producto cargado',
    getProductByIdFailure: 'Error al recuperar el producto',

    updateProductSuccess: 'Has actualizado tu producto',
    updateProductFailure: 'Error al actualizar el producto',

    deleteProductByIdSuccess: 'Has eliminado tu producto',
    deleteProductByIdFailure: 'Error al eliminar el producto'
};

export const filesCRUDFeedbackStrings = {
    saveFileInStorageSuccess: 'Has guardado el archivo',
    saveFileInStorageFailure: 'Error al guardar el archivo',

    getAllFilesSuccess: 'Archivos recuperados',
    getAllFilesFailure: 'Error al recuperar los archivos',

    getFileByIdSuccess: 'Archivo recuperado',
    getFileByIdFailure: 'Error al recuperar el archivo',

    deleteFileByFileNameSuccess: 'Has eliminado el archivo',
    deleteFileByFileNameFailure: 'Error al eliminar el archivo'
};

export const usersCRUDFeedbackStrings = {
    createUserSuccess: 'Has creado un nuevo usuario',
    createUserFailure: 'Error al crear el usuario',

    getAllUsersSuccess: 'Usuarios recuperado',
    getAllUsersFailure: 'Error al recuperar los usuarios',

    getUserByIdSuccess: 'Usuario recuperado',
    getUserByIdFailure: 'Error al recuperar el usuario',

    updateUserSuccess: 'Has actualizado el perfil de usuario',
    updateUserFailure: 'Error al actualizar el usuario',

    deleteUserByIdSuccess: 'Has eliminado el usuario',
    deleteUserByIdFailure: 'Error al eliminar el usuario'
}

export const webpageCRUDFeedbackStrings = {
    webpageDataSuccess: 'Página cargada',
    webpageDataFailure: 'Error al cargar la página',

    updateWebpageDataSuccess: 'Has actualizado la página',
    updateWebpageDataFailure: 'Error al actualizar la página'
};

export const votesCRUDFeedbackStrings = {
    emitNewVoteSuccess: 'Has emitido tu voto',
    emitNewVoteFailure: 'Error al guardar tu voto',

    getVotesByMethodSuccess: 'Se han recabado los votos',
    getVotesByMethodFailure: 'No se han conseguido recuperar los votos',

    getVoteByIdSuccess: 'Se ha recabado el voto',
    getVoteByIdFailure: 'No se ha conseguido recuperar el voto',

    updateVoteSuccess: 'Has editado tu voto',
    updateVoteFailure: 'No se ha conseguido editar tu voto',

    deleteVoteByIdSuccess: 'Has eliminado tu voto',
    deleteVoteByIdFailure: 'No se ha conseguido eliminar tu voto',
};

export const collectionsCRUDFeedbackStrings = {
    createNewCollectionSuccess: 'Has creado una nueva colección',
    createNewCollectionFailure: 'Error al crear la colección',

    addProductToCollectionSuccess: 'Has asignado un producto a la colección',
    addProductToCollectionFailure: 'Error al asignar el producto',

    addVoteToCollectionSuccess: 'Has asignado un voto a la colección',
    addVoteToCollectionFailure: 'Error al asignar el voto',

    getActiveCollectionsSuccess: 'Colecciones activas cargadas',
    getActiveCollectionsFailure: 'Error al recuperar las colecciones',

    getActiveFullCollectionsSuccess: 'Colecciones activas cargadas',
    getActiveFullCollectionsFailure: 'Error al recuperar las colecciones',

    getCollectionByIdSuccess: 'Colección cargada',
    getCollectionByIdFailure: 'Error al recuperar la colección',

    getFullCollectionByIdSuccess: 'Colección cargada',
    getFullCollectionByIdFailure: 'Error al recuperar la colección',

    getAllCollectionsSuccess: 'Colecciones cargadas',
    getAllCollectionsFailure: 'Error al recuperar las colecciones',

    getAllFullCollectionsSuccess: 'Colecciones cargadas',
    getAllFullCollectionsFailure: 'Error al recuperar las colecciones',

    updateCollectionSuccess: 'Has actualizado la colección',
    updateCollectionFailure: 'Error al actualizar la colección',

    deleteCollectionByIdSuccess: 'Has eliminado la colección',
    deleteCollectionByIdFailure: 'Error al eliminar la colección'
};