import { auth } from './firebase';
import { firestore } from './firebase';
import { authFeedbackStrings as feedback, throwCustomError } from './setup';
import { getUserById } from './usersCRUD';

export const emailExistsInDB = async (email) => {
    try {
        const mailInDB = [];
        const existingEmails = await firestore.collection('Users').where('email', '==', email).get();
        await existingEmails.forEach(doc => {
            mailInDB.push(doc.data());
        })
        return !!mailInDB.length;
    } catch (err) {
        throwCustomError('Ha habido un error tratando de comprobar si el mail existe');
    }
}

export const existingEmails = async (emails) => {
    try {
        const existingEmailsArray = []
        for (const email of emails) {
            const exists = await emailExistsInDB(email);
            console.log(exists);
            if (exists) {
                existingEmailsArray.push(email)
            }
        }
        return existingEmailsArray;
    } catch (err) {
        throwCustomError('Ha habido un error tratando de recuperar los emails');
    }
};

export const loginWithEmailAndPassword = async (email, password) => {
    try {
        await auth.signInWithEmailAndPassword(email, password);
        return {
            status: 200,
            msg: feedback.loginWithEmailAndPasswordSuccess,
        };
    } catch (err) {
        throwCustomError(feedback.loginWithEmailAndPasswordFailure);
    }
};

export const logout = async () => {
    try {
        await auth.signOut();
        return {
            status: 200,
            msg: feedback.logoutSuccess,
        };
    } catch (err) {
        throwCustomError(feedback.logoutFailure);
    }
};

export const getCurrentUserData = async () => {
    try {
        const currentUser = await auth.currentUser;
        const fetchedCurrentUserData = await getUserById(currentUser.uid);
        return {
            status: 200,
            msg: feedback.getCurrentUserDataSuccess,
            user: fetchedCurrentUserData.user,
        };
    } catch (err) {
        throwCustomError(feedback.getCurrentUserDataFailure);
    }
};

export const recoverPasswordByEmail = async (email) => {
    try {
        await auth.sendPasswordResetEmail(email);
        return {
            status: 200,
            msg: feedback.recoverPasswordByEmail,
        };
    } catch (err) {
        return {
            // Should be 500, but we keep 200 to simulate the same response as
            // if it were a success, not revealing if the account exists or not
            status: 200,
            msg: feedback.recoverPasswordByEmail,
        };
    }
};
