import { firestore } from "./firebase";
import { blogCategoriesCRUDFeedbackStrings as feedback, throwCustomError } from './setup'

const updateAllAffectedPosts = async (oldCategoryName, newCategoryName) => {
    try {
        const querySnapshot = await firestore.collection('Posts').where('category', '==', oldCategoryName).get();

        querySnapshot.forEach(async (doc) => {
            await firestore.collection('Posts').doc(doc.id).update({category: newCategoryName || 'Sin categoría'});
        });
    } catch (err) {
        console.log(err);
        throw new Error(err);        
    }
}

const getOldCategoryData = async (categoryId) => {
    try {
        const fetchCategory = await firestore.collection('PostsCategories').doc(categoryId).get();
        const categoryData = fetchCategory.data();
        return {
            title: categoryData.title,
            id: categoryId,
        }
    } catch (err) {
        console.log(err);
        throw new Error(err)
    }
}

export const createNewPostCategory = async (postCategory) => {
    try {
        await firestore.collection('PostsCategories').add(postCategory);
        return {
            status: 200,
            msg: feedback.createNewPostCategorySuccess,
        }
    } catch (err) {
        console.log(err)
        throwCustomError(feedback.createNewPostCategoryFailure);
    }
}

export const getAllPostsCategories = async () => {
    try {
        const querySnapshot = await firestore.collection('PostsCategories').get();

        const postsCategoriesArray = [];
        querySnapshot.forEach(doc => {
            postsCategoriesArray.push({id: doc.id, ...doc.data()});
        });

        return {
            status: 200,
            msg: feedback.getAllPostsCategoriesSuccess,
            categories: postsCategoriesArray
        }
    } catch (err) {
        throwCustomError(feedback.getAllPostsCategoriesFailure);
    }
}

export const updatePostCategory = async (postCategory) => {
    try {
        await firestore.collection('PostsCategories').doc(postCategory.id).update({...postCategory})

        const previousData = await getOldCategoryData(postCategory.id);
        if (postCategory.title !== previousData.title) {
            await updateAllAffectedPosts(previousData.title, postCategory.title)
        }
        // Check this!
        //await updateAllAffectedPosts(postCategory.title);

        return {
            status: 200,
            msg: feedback.updatePostCategorySuccess,
        }
    } catch (err) {
        throwCustomError(feedback.updatePostCategoryFailure);
    }
}

export const deletePostCategoryById = async postCategoryId => {
    try {

        const category = await getOldCategoryData(postCategoryId);

        await updateAllAffectedPosts(category.title);
        await firestore.collection('PostsCategories').doc(postCategoryId).delete();

        return {
            status: 200,
            msg: feedback.deletePostCategoryByIdSuccess,
        }
    } catch (err) {
        console.log(err);
        throwCustomError(feedback.deletePostCategoryByIdFailure);
    }
}