<template>
    <section>
        <Loader v-if="isLoading" />
        <template v-else>
            <ActionsBar @save="saveHandler" :save="true" />
            <div class="cookies">
                <div class="cookies__header">
                    <h3 class="cookies__header-title">Editor de Política de cookies</h3>
                    <p class="cookies__header-text">
                        En esta sección podrás <b>editar, crear y modificar</b> la sección de Política de cookies.
                    </p>
                    <p class="cookies__header-text">
                        Solo se admite texto en esta sección y actualmente el editor
                        <b>no soporta la subida de imágenes</b>.
                    </p>
                </div>

                <VueEditor placeholder="Sección de terminos y condiciones" v-model="cookies" />
            </div>
        </template>
    </section>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import { getWebpageData, updateWebpageData } from '../services/firebase/webpageCRUD';

import { createToast } from '../utils/utils';

import Loader from '../components/generic/Loader.vue';
import ActionsBar from '../components/bars/ActionsBar.vue';
export default {
    components: {
        ActionsBar,
        Loader,
        VueEditor,
    },
    data() {
        return {
            loading: false,
            changes: false,
            cookies: '',
        };
    },
    methods: {
        // Handler to save all data
        saveHandler() {
            updateWebpageData({
                cookies: this.cookies,
            })
                .then(({ msg }) => {
                    this.changes = false;
                    createToast({
                        text: msg,
                        type: 'success',
                        duration: 2500,
                    });
                })
                .catch(({ msg }) => {
                    createToast({
                        text: msg,
                        type: 'error',
                    });
                });
        },

        // Handler to fetch data
        fetchData() {
            this.loading = true;
            getWebpageData().then(({ webpageData }) => {
                const { cookies } = webpageData;
                this.cookies = cookies;
                this.loading = false;
            });
        },
    },
    computed: {
        isLoading() {
            return this.loading;
        },
    },
    created() {
        this.fetchData();
    },
};
</script>

<style lang="less">
@import '../assets/css/viewsStyles/cookies.less';
</style>
