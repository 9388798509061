<template>
    <section class="leftBar">
        <div class="leftBar__menu">
            <div class="leftBar__menu-items" :class="isActiveMenu('home')" @click="submenuHandler('home')">
                <FontAwesomeIcon class="leftBar__menu-icon" icon="user-edit" />
            </div>
            <div class="leftBar__menu-items" :class="isActiveMenu('legal')" @click="submenuHandler('legal')">
                <FontAwesomeIcon class="leftBar__menu-icon" icon="balance-scale" />
            </div>
            <div class="leftBar__menu-items" :class="isActiveMenu('user')" @click="submenuHandler('user')">
                <FontAwesomeIcon class="leftBar__menu-icon" icon="user-cog" />
            </div>
            <div class="leftBar__menu-items" :class="isActiveMenu('products')" @click="submenuHandler('products')">
                <FontAwesomeIcon class="leftBar__menu-icon" icon="money-bill-wave" />
            </div>
        </div>

        <div class="leftBar__submenu">
            <div v-if="submenu" class="leftBar__submenu-backdrop" @click="submenuHandler('')"></div>

            <div class="leftBar__submenu-item" :class="isOpen('home')">
                <p class="leftBar__submenu-item-title">Página principal</p>
                <router-link to="/slider" class="leftBar__submenu-item-nav"
                    ><span @click="closeMenuHandler"
                        >Editar Slider<FontAwesomeIcon class="leftBar__submenu-item-icon" icon="angle-right"/></span
                ></router-link>
                <router-link to="/about" class="leftBar__submenu-item-nav"
                    ><span @click="closeMenuHandler"
                        >Editar Sobre nosotros<FontAwesomeIcon
                            class="leftBar__submenu-item-icon"
                            icon="angle-right"/></span
                ></router-link>
                <router-link to="/partners" class="leftBar__submenu-item-nav"
                    ><span @click="closeMenuHandler"
                        >Editar Partners<FontAwesomeIcon class="leftBar__submenu-item-icon" icon="angle-right"/></span
                ></router-link>
                <router-link to="/secondary-partners" class="leftBar__submenu-item-nav"
                    ><span @click="closeMenuHandler"
                        >Editar Partners Secundarios<FontAwesomeIcon
                            class="leftBar__submenu-item-icon"
                            icon="angle-right"/></span
                ></router-link>
                <router-link to="/projects" class="leftBar__submenu-item-nav"
                    ><span @click="closeMenuHandler"
                        >Editar Proyectos<FontAwesomeIcon class="leftBar__submenu-item-icon" icon="angle-right"/></span
                ></router-link>
                <router-link to="/servicios" class="leftBar__submenu-item-nav"
                    ><span @click="closeMenuHandler"
                        >Editar Servicios<FontAwesomeIcon class="leftBar__submenu-item-icon" icon="angle-right"/></span
                ></router-link>

                <router-link to="/contact" class="leftBar__submenu-item-nav"
                    ><span @click="closeMenuHandler"
                        >Editar Contacto<FontAwesomeIcon class="leftBar__submenu-item-icon" icon="angle-right"/></span
                ></router-link>
                <router-link to="/files" class="leftBar__submenu-item-nav"
                    ><span @click="closeMenuHandler"
                        >Gestor de archivos<FontAwesomeIcon
                            class="leftBar__submenu-item-icon"
                            icon="angle-right"/></span
                ></router-link>
            </div>

            <div class="leftBar__submenu-item" :class="isOpen('legal')">
                <p class="leftBar__submenu-item-title">Páginas legales</p>
                <router-link to="/terms" class="leftBar__submenu-item-nav"
                    ><span @click="closeMenuHandler"
                        >Editar Términos y condiciones<FontAwesomeIcon
                            class="leftBar__submenu-item-icon"
                            icon="angle-right"/></span
                ></router-link>
                <router-link to="/privacity" class="leftBar__submenu-item-nav"
                    ><span @click="closeMenuHandler"
                        >Editar Política de privacidad<FontAwesomeIcon
                            class="leftBar__submenu-item-icon"
                            icon="angle-right"/></span
                ></router-link>
                <router-link to="/cookies" class="leftBar__submenu-item-nav"
                    ><span @click="closeMenuHandler"
                        >Editar Cookies<FontAwesomeIcon class="leftBar__submenu-item-icon" icon="angle-right"/></span
                ></router-link>
            </div>

            <div class="leftBar__submenu-item" :class="isOpen('user')">
                <p class="leftBar__submenu-item-title">
                    Editor de Usuario<FontAwesomeIcon class="leftBar__submenu-item-icon" icon="angle-right" />
                </p>
                <router-link to="/invitations" class="leftBar__submenu-item-nav"
                    ><span @click="closeMenuHandler"
                        >Invitar a usuarios<FontAwesomeIcon
                            class="leftBar__submenu-item-icon"
                            icon="angle-right"/></span
                ></router-link>
                <router-link to="/users" class="leftBar__submenu-item-nav"
                    ><span @click="closeMenuHandler"
                        >Lista de usuarios<FontAwesomeIcon class="leftBar__submenu-item-icon" icon="angle-right"/></span
                ></router-link>
            </div>

            <div class="leftBar__submenu-item" :class="isOpen('products')">
                <p class="leftBar__submenu-item-title">Productos</p>
                <router-link to="/products-categories" class="leftBar__submenu-item-nav"
                    ><span @click="closeMenuHandler"
                        >Categorías<FontAwesomeIcon class="leftBar__submenu-item-icon" icon="angle-right"/></span
                ></router-link>
                <router-link to="/products" class="leftBar__submenu-item-nav"
                    ><span @click="closeMenuHandler"
                        >Productos<FontAwesomeIcon class="leftBar__submenu-item-icon" icon="angle-right"/></span
                ></router-link>

                <router-link to="/collections" class="leftBar__submenu-item-nav"
                    ><span @click="closeMenuHandler"
                        >Colecciones<FontAwesomeIcon class="leftBar__submenu-item-icon" icon="angle-right"/></span
                ></router-link>
                <router-link to="/results" class="leftBar__submenu-item-nav"
                    ><span @click="closeMenuHandler"
                        >Resultados<FontAwesomeIcon class="leftBar__submenu-item-icon" icon="angle-right"/></span
                ></router-link>
            </div>
        </div>
    </section>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faUserCog,
    faBookReader,
    faUserEdit,
    faBalanceScale,
    faAngleRight,
    faMoneyBillWave,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faUserEdit, faUserCog, faBookReader, faBalanceScale, faAngleRight, faMoneyBillWave);

export default {
    components: {
        FontAwesomeIcon,
    },
    data() {
        return {
            submenu: null,
        };
    },
    methods: {
        // Open specific menu
        submenuHandler(target) {
            if (this.submenu === target) {
                this.submenu = null;
                return;
            }

            this.submenu = target;
        },

        // Check if this menu element is open
        isOpen(target) {
            return this.submenu === target ? 'leftBar__submenu--open' : '';
        },

        // Check if this menu element is active
        isActiveMenu(target) {
            return this.submenu === target ? 'leftBar__menu-items--active' : '';
        },

        // Close menu
        closeMenuHandler() {
            this.submenu = null;
        },
    },
};
</script>

<style lang="less">
@import '../../assets/css/bars/left-bar.less';
</style>
