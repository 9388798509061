<template>
    <section>
        <Loader v-if="isLoading" />
        <template v-else>
            <ActionsBar @save="saveHandler" :save="true" :changes="hasChanges" v-if="!editing" />
            <div class="partners">
                <div class="partners__header">
                    <h3 class="partners__header-title">Editor de Partners Secundarios</h3>
                    <p class="partners__header-text">
                        En esta sección podrás <b>editar, crear y modificar</b> la sección de Partners.
                    </p>
                    <p class="partners__header-text">
                        Cada partner está compuesto de manera obligatoria por los siguientes campos:
                    </p>
                    <ul class="partners__header-list">
                        <li>Imagen: Un único fichero</li>
                        <li>Enlace: Dirección a donde será redirigido si el usuario hace click sobre el</li>
                    </ul>
                </div>

                <EditPartners
                    @partnerData="handlerPartnerData"
                    @partnerRemove="handlerRemovePartner"
                    @editingEnable="editingHandler"
                    :partnerData="partners"
                />
            </div>
        </template>
    </section>
</template>

<script>
import { getWebpageData, updateWebpageData } from '../services/firebase/webpageCRUD';

import { createToast } from '../utils/utils';

import Loader from '../components/generic/Loader.vue';
import ActionsBar from '../components/bars/ActionsBar.vue';

import EditPartners from '../components/cms/EditPartners.vue';
export default {
    components: {
        EditPartners,
        ActionsBar,
        Loader,
    },
    data() {
        return {
            loading: false,
            changes: false,
            editing: false,
            partners: [],
        };
    },
    methods: {
        // Handler to persist partnet info
        handlerPartnerData(image, url, partnerIndex) {
            this.changes = true;

            console.log(111111, this.partners)

            if (this.partners.some((partner, index) => index === partnerIndex)) {
                this.partners[partnerIndex].image = image;
                this.partners[partnerIndex].url = url;
                return;
            }

            this.partners.push({
                image,
                url,
            });
        },

        // Hanlder to remove partner data
        handlerRemovePartner(partnerIndex) {
            this.changes = true;
            this.partners = this.partners.filter((partner, index) => index !== partnerIndex);
        },

        // Handler to save all data
        saveHandler() {
            updateWebpageData({
                secondaryPartners: this.partners,
            })
                .then(({ msg }) => {
                    this.changes = false;
                    createToast({
                        text: msg,
                        type: 'success',
                        duration: 2500,
                    });
                })
                .catch(({ msg }) => {
                    createToast({
                        text: msg,
                        type: 'error',
                    });
                });
        },

        // Handler to fetch data
        fetchData() {
            this.loading = true;
            getWebpageData().then(({ webpageData }) => {
                const { secondaryPartners } = webpageData;
                this.partners = secondaryPartners || [];
                this.loading = false;
            });
        },

        // Emit a boolean to hide Save button
        editingHandler(value) {
            this.editing = value;
        },
    },
    computed: {
        isLoading() {
            return this.loading;
        },

        hasChanges() {
            return this.changes;
        },
    },
    created() {
        this.fetchData();
    },
};
</script>

<style lang="less">
@import '../assets/css/viewsStyles/partners.less';
</style>
