import { auth, firestore } from "./firebase";
import { usersCRUDFeedbackStrings as feedback, throwCustomError } from "./setup";

export const createUser = async (user) => {
    try {
        const {password, ...userData} = user;
        const credentials = await auth.createUserWithEmailAndPassword(
            userData.email,
            password
        );
        await firestore.collection('Users').doc(credentials.user.uid).set({
            ...userData,
            id: credentials.user.uid
        });
        return {
            status: 200,
            msg: feedback.createUserSuccess,
        }
    } catch (err) {
        throwCustomError(feedback.createUserFailure);
    }
}

export const getAllUsers = async () => {
    try {
        const querySnapshot = await firestore.collection('Users').get();

        const usersArray = [];
        querySnapshot.forEach(doc => {
            usersArray.push({...doc.data()});
        });

        return {
            status: 200,
            msg: feedback.getAllUsersSuccess,
            users: usersArray
        }
    } catch (err) {
        throwCustomError(feedback.getAllUsersFailure);
    }
}

export const getUserById = async uid => {
    try {
        const user = await firestore.collection('Users').doc(uid).get();
        return {
            status: 200,
            msg: feedback.getUserByIdSuccess,
            user: user.data()
        }
    } catch (err) {
        throwCustomError(feedback.getUserByIdFailure);
    }
}

export const updateUser = async (user) => {
    try {
        await firestore.collection('Users').doc(user.id).update({...user});
        return {
            status: 200,
            msg: feedback.updateUserSuccess,
        }
    } catch (err) {
        throwCustomError(feedback.updateUserFailure);
    }
}

export const deleteUserById = async uid => {
    try {
        await firestore.collection('Users').doc(uid).delete();
        //TODO: remove user form auth (needs backend)
        //await deleteUserFromAuthSystem(uid);
        return {
            status: 200,
            msg: feedback.deleteUserByIdSuccess,
        }
    } catch (err) {
        throwCustomError(feedback.deleteUserByIdFailure);
    }
}
