<!--
TODO:
    - Edición de la imagen destacada
    - Dividir en componentes para reutilización
-->
<template>
    <section>
        <Loader v-if="isLoading" />
        <template v-else>
            <div class="new-post-frame">
                <div class="new-post-header">
                    <div class="new-post-header__title">
                        <h1>{{ editMode ? 'Editar entrada' : 'Añadir nueva entrada' }}</h1>
                    </div>
                    <div class="new-post-header__actions">
                        <button v-if="editMode" id="cancel-btn" @click="cancelHandler">Cancelar</button>
                        <button v-if="editMode" id="update-btn" @click="updateHandler">Actualizar</button>
                        <button v-if="!editMode" id="publish-btn" @click="savePostHandler">Publicar</button>
                    </div>
                </div>
                <div class="new-post-body">
                    <div class="editor">
                        <label class="form-label" for="post-title">
                            Título del artículo
                        </label>
                        <input
                            id="post-title"
                            class="form-input"
                            :class="isValidFeedback.title ? '' : 'error'"
                            @focus="correct('title')"
                            type="text"
                            v-model="post.title"
                            placeholder="Escribe aquí el título del artículo"
                        />
                        <VueEditor
                            placeholder="Escribe aquí tu artículo"
                            v-model="post.content"
                            :class="isValidFeedback.content ? '' : 'error'"
                            @focus="correct('content')"
                        />
                    </div>
                    <div class='categories-wrapper'>
                        <h2>Selecciona una categoría</h2>
                        <select
                            name="category"
                            class="categories-selector"
                            :class="isValidFeedback.category ? '' : 'error'"
                            @focus="correct('category')"
                            v-model="post.category"
                        >
                            <option value=""></option>
                            <option
                                v-for="category in categoriesList"
                                :key="category.title"
                                :value="category.title"
                            >
                                {{ category.title }}
                            </option>
                        </select>
                    </div>
                    <div class="meta">
                        <div class="image">
                            <h2>Imagen</h2>
                            <form>
                                <label for="name" class="form-label">
                                    Título de la imagen:
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    class="form-input"
                                    v-model="post.image.title"
                                    :class="isValidFeedback.image.title ? '' : 'error'"
                                    @focus="correct('image', 'title')"
                                />
                                <label for="alt" class="form-label">
                                    Descripción de la imagen (alt):
                                </label>
                                <input
                                    type="text"
                                    name="alt"
                                    id="alt"
                                    class="form-input"
                                    v-model="post.image.alt"
                                    :class="isValidFeedback.image.alt ? '' : 'error'"
                                    @focus="correct('image', 'alt')"
                                />
                                <div class="file-input" v-if="!previewUrl">
                                    <label class="form-label">
                                        <svg
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="cloud-upload-alt"
                                            class="svg-inline--fa fa-cloud-upload-alt fa-w-20"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 640 512"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zM393.4 288H328v112c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V288h-65.4c-14.3 0-21.4-17.2-11.3-27.3l105.4-105.4c6.2-6.2 16.4-6.2 22.6 0l105.4 105.4c10.1 10.1 2.9 27.3-11.3 27.3z"
                                            ></path>
                                        </svg>
                                        <input type="file" @change="previewFileHandler" />
                                    </label>
                                    <p>Sube aquí tu imagen</p>
                                </div>
                                <div class="preview">
                                    <img :src="previewUrl" alt="" />
                                </div>
                                <button type="submit" v-if="previewUrl" @click="resetFileHandler">Resetear</button>
                            </form>
                        </div>
                        <div class="seo">
                            <h2>SEO</h2>
                            <label class="form-label" for="path">
                                Ruta del artículo
                            </label>
                            <input
                                id="path"
                                class="form-input"
                                type="text"
                                v-model="post.path"
                                :class="isValidFeedback.path ? '' : 'error'"
                                @focus="correct('path')"
                            />
                            <label class="form-label" for="metatitle">
                                Metatítulo
                            </label>
                            <input
                                for="metatitle"
                                class="form-input"
                                type="text"
                                v-model="post.metatitle"
                                :class="isValidFeedback.metatitle ? '' : 'error'"
                                @focus="correct('metatitle')"
                            />
                            <label class="form-label" for="metadescription">
                                Metadescripción
                            </label>
                            <textarea
                                for="metadescription"
                                rows="5"
                                class="form-input"
                                type="text"
                                v-model="post.metadescription"
                                :class="isValidFeedback.metadescription ? '' : 'error'"
                                @focus="correct('metadescription')"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </section>
</template>

<script>
import {VueEditor} from 'vue2-editor';
import Loader from '../../components/generic/Loader.vue'
import { saveFileInStorage } from '../../services/firebase/filesCRUD';
import { getAllPostsCategories } from '../../services/firebase/blogCategoriesCRUD';
import { createNewPost, updatePost } from '../../services/firebase/blogCRUD';
import { createToast } from '../../utils/utils';



const feedbackInitialState = {
    title: true,
    content: true,
    path: true,
    metatitle: true,
    metadescription: true,
    category: true,
    image: {
        title: true, //imageName
        alt: true, // alt
        url: true // res.fileUrl ***
    }
}

export default {
    layout: 'admin',
    components: {
        VueEditor,
        Loader
    },
    props: ['editMode', 'oldPost'],
    data() {
        return {
            loading: false,
            categoriesList: [],
            post: {
                title: this?.oldPost?.title || '',
                content: this?.oldPost?.content || '',
                path: this?.oldPost?.path || '',
                metatitle: this?.oldPost?.metatitle || '',
                metadescription: this?.oldPost?.metadescription || '',
                category: this?.oldPost?.category || '',
                image: {
                    title: this?.oldPost?.image?.title || '', //imageName
                    alt: this?.oldPost?.image?.alt || '', // alt
                    url: this?.oldPost?.image?.url || '' // res.fileUrl ***
                }
            },
            feedbackState: {...feedbackInitialState},
            previewUrl: this?.oldPost?.image?.url || '',
            uploadedFile: '',
        };
    },
    computed: {
        isValidFeedback() {
            return this.feedbackState;
        },
        isLoading() {
            return this.loading;
        }
    },
    methods: {
        fetchData() {
            this.loading = true;
            getAllPostsCategories().then(({ categories }) => {

                this.categoriesList = categories;
                this.loading = false;
            });
        },
        customError(msg, param, subparam) {
            subparam ?
            this.feedbackState[param][subparam] = false :
            this.feedbackState[param] = false;
            const error = new Error;
            error.msg = msg;
            throw error;
        },
        validatePath() {
            // Convendría recabar todas las rutas para dar feedback y anticipar el siguiente paso
            if (!this.post.path.length) {
                //Crear una ruta

                //DELETE:
                this.customError('Tu artículo necesita una ruta', 'path');
            }
            // Comprobar que la ruta no colisiona con otras
            // Si colisiona, crear nueva ruta (por ejemplo, añadiendo -i al final hasta que cuadre)
        },
        validateInput() {
            if (!this.post.title.length) {
                this.customError('Tu artículo necesita un título', 'title');
            }
            if (!this.post.content.length) {
                this.customError('Tu artículo necesita contenido', 'content');
            }
            if (!this.post.metatitle.length) {
                this.customError('Tu artículo necesita un metatítulo', 'metatitle');
            }
            if (!this.post.metadescription.length) {
                this.customError('Tu artículo necesita una metadescripción', 'metadescription');
            }
            if (!this.post.category.length) {
                this.customError('Tu artículo necesita una categoría', 'category');
            }
            if (!this.post.image.title.length) {
                this.customError('Tu imagen destacada necesita un nombre', 'image', 'title');
            }
            if (!this.post.image.alt.length) {
                this.customError('Tu imagen destacada necesita una descripción', 'image', 'alt');
            }
            if (!this.post.image.url.length) {
                this.customError('Tu artículo necesita una imagen destacada', 'image', 'url');
            }
            this.validatePath()
        },
        correct(key, subkey) {
            subkey ?
            this.feedbackState[key][subkey] = true :
            this.feedbackState[key] = true;
        },
        previewFileHandler(e) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.previewUrl = reader.result;
                this.uploadedFile = file;
            };
        },
        resetFileHandler() {
            this.previewUrl = '';
            this.uploadedFile = '';
        },
        savePostHandler() {
            saveFileInStorage(this.uploadedFile)
                .then((res) => {
                    this.post.image.url = res.fileUrl;
                    this.validateInput();
                    createNewPost(this.post)
                        .then(({msg}) => {
                            createToast({
                                text: msg,
                                type: 'success',
                                duration: 2500,
                            });
                        });
                })
                .catch((err) => {

                    console.log(err);
                    if (err.msg === 'Error al guardar el archivo') {
                        err.msg = 'Tu artículo necesita una imagen destacada'
                    }
                    createToast({
                        text: err.msg,
                        type: 'error',
                        duration: 2500,
                    });
                });
        },
        cancelHandler() {
            this.$emit('cancel');
        },
        updateHandler() {
            const imageHasChanged = this.previewUrl !== this?.oldPost?.image?.url;
            if (imageHasChanged) {
                saveFileInStorage(this.uploadedFile)
                .then((res) => {
                    this.post.image.url = res.fileUrl;
                    this.validateInput();
                    updatePost({ ...this.post, id: this.oldPost.id })
                        .then(({msg}) => {
                            createToast({
                                text: msg,
                                type: 'success',
                                duration: 2500,
                            });
                        });
                })
                .then(() => {
                    this.$emit('updatePost');
                })
                .catch((err) => {
                    if (err.msg === 'Error al guardar el archivo') {
                        err.msg = 'Tu artículo necesita una imagen destacada'
                    }
                    createToast({
                        text: err.msg,
                        type: 'error',
                        duration: 2500,
                    });
                });
            } else {
                updatePost({ ...this.post, id: this.oldPost.id })
                .then(res => {
                    createToast({
                        text: res.msg,
                        type: 'success',
                        duration: 2500,
                    });
                })
                .then(() => {
                    this.$emit('updatePost');
                })
                .catch(err => {
                    console.log(err)
                    createToast({
                        text: err.msg,
                        type: 'error',
                        duration: 2500,
                    });
                });
            }
        }
    },
    created() {
        this.fetchData();
    },
};
</script>

<style scoped>
.new-post-frame {
    background-color: white;
    padding: 1rem;
    margin: 1rem;
    border-radius: 5px;
    box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 20%);
    display: flex;
    flex-direction: column;
}
.new-post-header {
    border-bottom: 1px solid grey;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.new-post-header__title {
    font-size: 1.5rem;
}
.new-post-header__actions {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
#post-title {
    margin-bottom: 2rem;
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
}
#publish-btn,
[type='submit'] {
    background-color: #082130;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    font-size: 1.5rem;
}
#publish-btn:hover,
#publish-btn:active,
[type='submit']:hover,
[type='submit']:active {
    background-color: #1b5a80;
    transform: translateY(2px);
    cursor: pointer;
}
.new-post-body {
    margin: 2rem 0;
}
.editor {
    display: flex;
    flex-direction: column;
}
.categories-wrapper {
    margin: 1rem;
    padding: 1rem;
    border: 1px solid grey;
    border-radius: 5px;
}
.categories-selector {
    width: 100%;
    padding: 0.5rem 1rem;
}
.meta {
    display: flex;
    justify-content: space-between;
}
.image {
    margin: 1rem;
    padding: 1rem;
    border: 1px solid grey;
    flex-grow: 1;
    max-width: 40%;
    border-radius: 5px;
}
.preview {
    margin: 2rem 0;
}
.preview img {
    width: 100%;
}
.seo {
    margin: 1rem;
    padding: 1rem;
    border: 1px solid grey;
    flex-grow: 1;
    max-width: 40%;
    border-radius: 5px;
}

form,
.seo {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.form-label {
    margin: 0.5rem 0;
}
.form-input {
    border: 1px solid grey;
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
}
.file-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}
.file-input label {
    cursor: pointer;
}
.file-input svg {
    font-size: 3rem;
    color: #1b5a80;
}
.file-input input {
    display: none;
}
.error {
    border-color: red;
    background-color: salmon;
}
</style>
