<template>
    <div>
        <div class="file-container">
            <img :src="file.path" class="file-image" />
            <div class="delete-container" @click="deleteHandler">
                <div class="delete-btn">X</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'File',
    props: {
        file: Object,
    },
    data() {
        return {
            showingDetails: false,
        }
    },
    methods: {
        deleteHandler() {
            this.$emit('deleteImage', this.file.id);
        }
    }
}
</script>

<style scoped>
.file-container {
    width: 10rem;
    height: 10rem;
    margin: 1rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.file-image {
    width: 100%;
}
.delete-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000030;
    z-index: 10;
    opacity: 0;
}
.delete-container:hover {
    opacity: 1;
}
.delete-btn {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background-color: red;
    color: white;
    font-size: 2rem;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>