import { firestore } from "./firebase";

export const filterAndFetch = async (params) => {
    try {
        const results = [];
        const snapshot = params.startAfter
            ? await firestore.collection(params.collection).orderBy(params.criteria, params.order).startAfter(params.startAfter).limit(params.limit).get()
            : await firestore.collection(params.collection).orderBy(params.criteria, params.order).limit(params.limit).get()
        snapshot.forEach(doc => {
            results.push(doc.data());
        })
        const startAfter = await snapshot.docs[snapshot.docs.length -1];
        return {
            results: results,
            startAfter: startAfter,
            msg: 'Se han recuperado los resultados'
        }
    } catch (err) {
        console.log(err);
        const error = new Error;
        error.msg = 'Ha habido un error';
        throw error;
    }
}