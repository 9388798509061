<template>
    <section>
        <Loader v-if="isLoading" />
        <template v-else>
            <ActionsBar @save="saveHandler" :save="true" :changes="hasChanges" v-if="!editing" />
            <div class="products">
                <div class="products__header">
                    <h3 class="products__header-title">Editor de Productos</h3>
                    <p class="products__header-text">
                        En esta sección podrás <b>editar, crear y modificar</b> los productos que aparecen en la página
                        principal.
                    </p>
                    <p><b>CADA VEZ QUE SE CREA UN PRODUCTO SE DEBE GUARDAR.</b></p>
                    <p class="products__header-text">
                        Cada producto está compuesto de manera obligatoria por los siguientes campos:
                    </p>
                    <ul class="products__header-list">
                        <li>Imagen: La imagen del producto.</li>
                        <li>Título: El título que se muestra en el producto</li>
                        <li>Categoría: La categoría a la que pertenece el producto</li>
                        <li>Rango de precio: El precio que se establece para marcar uno aleatorio</li>
                    </ul>
                </div>

                <EditProducts
                    @productData="handlerProductData"
                    @productRemove="handlerRemoveProduct"
                    @editingEnable="editingHandler"
                    :productData="product"
                    :categoriesData="categories"
                />
            </div>
        </template>
    </section>
</template>

<script>
import { createNewProduct, getAllProducts, deleteProductById, updateProduct } from '../services/firebase/productsCRUD';
import { getAllProductsCategories } from '../services/firebase/productsCategoriesCRUD';

import { createToast } from '../utils/utils';

import Loader from '../components/generic/Loader.vue';
import ActionsBar from '../components/bars/ActionsBar.vue';

import EditProducts from '../components/products/EditProducts.vue';
export default {
    components: {
        EditProducts,
        ActionsBar,
        Loader,
    },
    data() {
        return {
            loading: false,
            changes: false,
            editing: false,
            product: [],
            categories: [],
            productCandidate: {},
        };
    },
    methods: {
        // Handler to persist product info
        handlerProductData(title, image, productIndex, categories, minPrice, maxPrice) {
            this.changes = true;
            this.productCandidate = {
                title,
                image,
                categories,
                minPrice,
                maxPrice,
            };

            if (this.product.some((product, index) => index === productIndex)) {
                this.updateProductHandler({ ...this.productCandidate, id: this.product[productIndex].id });
                this.changes = false;
                return;
            }

            this.product.push(this.productCandidate);
        },

        // Hanlder to remove product id
        handlerRemoveProduct(productId) {
            this.deleteHandler(productId);
        },

        // Handler to save all data
        saveHandler() {
            createNewProduct(this.productCandidate)
                .then(({ msg, status }) => {
                    if (status === 200) {
                        this.changes = false;
                        this.productCandidate = {};

                        createToast({
                            text: msg,
                            type: 'success',
                            duration: 2500,
                        });
                    }
                })
                .catch(({ msg }) => {
                    createToast({
                        text: msg,
                        type: 'error',
                    });
                });
        },

        updateProductHandler(product) {
            try {
                updateProduct(product)
                    .then(({ msg }) => {
                        createToast({
                            text: msg,
                            type: 'success',
                            duration: 2500,
                        });
                    })
                    .then(() => {
                        this.fetchData();
                        this.productCandidate = {};
                    });
            } catch ({ msg }) {
                createToast({
                    text: msg,
                    type: 'error',
                });
            }
        },

        // Handler to delete product by id
        deleteHandler(id) {
            try {
                deleteProductById(id)
                    .then(({ msg }) => {
                        createToast({
                            text: msg,
                            type: 'success',
                            duration: 2500,
                        });
                    })
                    .then(() => {
                        this.fetchData();
                    });
            } catch ({ msg }) {
                createToast({
                    text: msg,
                    type: 'error',
                });
            }
        },

        // Handler to fetch data
        fetchData() {
            this.loading = true;
            getAllProducts()
                .then(({ products }) => {
                    this.product = products;
                    this.loading = false;
                })
                .catch(({ msg }) => {
                    createToast({
                        text: msg,
                        type: 'error',
                    });
                });
        },

        fetchCategories() {
            getAllProductsCategories()
                .then(({ categories }) => {
                    this.categories = categories;
                })
                .catch(({ msg }) => {
                    createToast({
                        text: msg,
                        type: 'error',
                    });
                });
        },

        // Emit a boolean to hide Save button
        editingHandler(value) {
            this.editing = value;
        },
    },
    computed: {
        isLoading() {
            return this.loading;
        },

        hasChanges() {
            return this.changes;
        },
    },
    created() {
        this.fetchData();
        this.fetchCategories();
    },
};
</script>

<style lang="less">
@import '../assets/css/viewsStyles/products.less';
</style>
