<template>
    <li>
        <div class="item" v-if="!isEditting">
            <h3>{{ post.title }}</h3>
            <div class="actions">
                <button class="button" @click="toggleEditHandler">Editar</button>
                <button
                    class="button delete"
                    @click="deleteHandler"
                >Eliminar</button>
            </div>
        </div>
        <div class="editor" v-if="isEditting">
            <EditPost
                :editMode="true"
                :oldPost="post"
                @cancel="toggleEditHandler"
                @updatePost="() => $emit('updatePost')"
            />
        </div>
    </li>
</template>

<script>
import {deletePostById} from '../../services/firebase/blogCRUD';
import EditPost from '../blog/EditPost.vue';
export default {
    props: ['post'],
    components: {
        EditPost,
    },
    data() {
        return {
            isEditting: false,
        }
    },
    methods: {
        toggleEditHandler() {
            this.isEditting = !this.isEditting;
        },
        deleteHandler() {
            deletePostById(this.post.id);
            this.$emit('updatePost');
        }
    }
}
</script>

<style>
@import '../../assets/css/blog/BlogItem.less';
</style>