<template>
    <section>
        <Loader v-if="isLoading" />
        <template v-else>
            <section v-if="isLoggedIn">
                <TopBar @logout="logoutHandler" :user="currentUser" />
                <LeftBar />
                <section class="bodyContent">
                    <routerView />
                </section>
            </section>
            <Login v-if="!isLoggedIn" @setLoggedInUserData="setLoggedInUserDataHandler" />
        </template>
    </section>
</template>

<script>
import { getCurrentUserData, logout } from '../src/services/firebase/auth';
import { createToast } from '../src/utils/utils.js';
import { auth } from '../src/services/firebase/firebase';

import Loader from '../src/components/generic/Loader.vue';

import TopBar from '../src/components/bars/TopBar.vue';
import LeftBar from '../src/components/bars/LeftBar.vue';
import Login from '../src/components/forms/Login.vue';
export default {
    components: {
        TopBar,
        LeftBar,
        Login,
        Loader,
    },
    data() {
        return {
            currentUser: {},
            loading: false,
        };
    },
    computed: {
        isLoggedIn() {
            const currentUserKeys = Object.keys(this.currentUser);
            if (currentUserKeys.length) {
                return true;
            }
            return false;
        },

        isAdmin() {
            if (this.currentUser?.isAdmin) {
                return true;
            }
            return false;
        },

        isLoading() {
            return this.loading;
        },
    },
    methods: {
        setLoggedInUserDataHandler() {
            this.loading = true;
            getCurrentUserData()
                .then((response) => {
                    if (response.status === 200) {
                        this.currentUser = response.user;
                    }
                    this.loading = false;
                })
                .catch(({ msg }) => {
                    createToast({
                        text: msg,
                        type: 'error',
                    });
                });
        },

        logoutHandler() {
            logout()
                .then(() => {
                    this.currentUser = {};
                })
                .catch(({ err }) => {
                    createToast({
                        text: 'No se ha podido cerrar la sesión',
                        type: err,
                    });
                });
        },
    },
    beforeCreate() {
        auth.onAuthStateChanged((user) => {
            if (user) {
                this.setLoggedInUserDataHandler();
            }
        });
    },
};
</script>

<style lang="less">
@import '../src/assets/css/main.less';
@import '../src/assets/css/generic/toast-component.less';
</style>
