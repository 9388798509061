<template>
    <section>
        <Loader v-if="isLoading" />
        <template v-else>
            <div class="blog-categories">
                <div class="blog-categories__header">
                    <h3 class="blog-categories__header-title">Categorías del Blog</h3>
                    <p class="blog-categories__header-text">
                        En esta sección podrás <b>editar, crear y modificar</b> las categorías del Blog.
                    </p>
                </div>
                <div class="blog-categories__list">
                    <p v-if="!this.categoriesList.length">Todavía no existe ninguna categoría</p>
                    <ul v-if="this.categoriesList.length" class="blog-categories__items">
                        <li v-for="category in categoriesList" :key="category.id">
                            <EditCategory
                                :title="category.title"
                                :description="category.description"
                                :id="category.id"
                                @save="saveHandler"
                                @edit="editHandler"
                                @delete="deleteHandler"
                            />
                        </li>
                    </ul>
                </div>
                <div>
                    <EditCategory
                        title=""
                        description=""
                        id=""
                        @save="saveHandler"
                    />
                </div>
            </div>
        </template>
    </section>
</template>

<script>
import Loader from '../components/generic/Loader.vue';
import EditCategory from '../components/blog/EditCategory.vue'

import { createToast } from '../utils/utils';
import { getAllPostsCategories, createNewPostCategory, updatePostCategory, deletePostCategoryById } from '../services/firebase/blogCategoriesCRUD';

const validateInput = (input) => {
    if (!input.title.length) {
        return false;
    }
    if (!input.description.length) {
        return false;
    }
    return true;
}

export default {
    components: {
        Loader,
        EditCategory
    },
    data() {
        return {
            loading: false,
            categoriesList: [],
            isAdding: false,
            isEditting: false,
            newTitle: '',
            newDescription: ''
        };
    },
    methods: {
        addHandler() {
            this.isAdding = !this.isAdding;
        },
        cancelHandler() {
            this.newTitle = '';
            this.newDescription = '';
        },
        saveHandler(data) {
            const isValid = validateInput(data);
            if (!isValid) {
                createToast({
                        text: 'Introduce título y descripción',
                        type: 'error',
                    });
                return;
            }
            createNewPostCategory(data)
                .then(({msg}) => {
                    getAllPostsCategories().then(({ categories }) => {
                        this.categoriesList = categories;
                    });
                    createToast({
                        text: msg,
                        type: 'success',
                        duration: 2500,
                    });
                })
                .catch(({ msg }) => {
                    createToast({
                        text: msg,
                        type: 'error',
                    });
                });
        },
        editHandler(data) {
            const isValid = validateInput(data);
            if (!isValid) {
                createToast({
                        text: 'Introduce título y descripción',
                        type: 'error',
                    });
                return;
            }
            updatePostCategory(data)
                .then(({msg}) => {
                    getAllPostsCategories().then(({ categories }) => {
                        this.categoriesList = categories;
                    });
                    createToast({
                        text: msg,
                        type: 'success',
                        duration: 2500,
                    });
                })
                .catch(({ msg }) => {
                    createToast({
                        text: msg,
                        type: 'error',
                    });
                });
        },
        deleteHandler(categoryId) {
            deletePostCategoryById(categoryId)
                .then(({msg}) => {
                    getAllPostsCategories().then(({ categories }) => {
                        this.categoriesList = categories;
                    });
                    createToast({
                        text: msg,
                        type: 'success',
                        duration: 2500,
                    });
                })
                .catch(({ msg }) => {
                    createToast({
                        text: msg,
                        type: 'error',
                    });
                });
        },





        // TODO: fetch existing categories
        fetchData() {
            this.loading = true;
            getAllPostsCategories().then(({ categories }) => {

                this.categoriesList = categories;
                this.loading = false;
            });
        },
    },
    computed: {
        isLoading() {
            return this.loading;
        }
    },
    created() {
        this.fetchData();
    },
};
</script>

<style lang="less">
@import '../assets/css/viewsStyles/blogCategories.less';
</style>
