import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const config = {
    apiKey: "AIzaSyCCNO9FbPtp4ph_M8tiqKha2IFDIyXQ4T4",
    authDomain: "riverlion-4411c.firebaseapp.com",
    projectId: "riverlion-4411c",
    storageBucket: "riverlion-4411c.appspot.com",
    messagingSenderId: "109560060113",
    appId: "1:109560060113:web:070db54e0b795e00f98df0",
    measurementId: "G-ZF178CVT8S"
};

if(!firebase.apps.length) {
    firebase.initializeApp(config);
}

const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();

export { auth, firestore, storage };