import { firestore } from './firebase';
import { collectionsCRUDFeedbackStrings as feedback, throwCustomError } from './setup';

export const createNewCollectionAndGetId = async (collection) => {
    try {
        const today = new Date();
        let isActive;
        if (
            collection.startCollectionDate <= today &&
            collection.endCollectionDate >= today
            ) {
                isActive = true;
            } else {
                isActive = false;
            }
        let canVote;
        if (
            collection.startCollectionVoteDate <= today &&
            collection.endCollectionVoteDate >= today
            ) {
                canVote = true;
            } else {
                canVote = false;
            }
        const newCollection = await firestore.collection('Collections').add({ isActive: isActive, canVote: canVote, ...collection });
        if (canVote) {
            await fetch('https://us-central1-riverlion-4411c.cloudfunctions.net/notifyNewVotation');
        }
        return {
            status: 200,
            msg: feedback.createNewCollectionSuccess,
            id: newCollection.id,
        };
    } catch (err) {
        console.log(err);
        throwCustomError(feedback.createNewCollectionFailure);
    }
};

export const addProductToCollection = async (product, collectionId) => {
    try {
        await firestore
            .collection('Collections')
            .doc(collectionId)
            .collection('Products')
            .doc(product.id)
            .set(product);
        return {
            status: 200,
            msg: feedback.addProductToCollectionSuccess,
        };
    } catch (err) {
        console.log(err);
        throwCustomError(feedback.addProductToCollectionFailure);
    }
};

export const addVoteToCollection = async (vote, collectionId) => {
    try {
        await firestore
            .collection('Collections')
            .doc(collectionId)
            .collection('Votes')
            .add(vote);
        return {
            status: 200,
            msg: feedback.addVoteToCollectionSuccess,
        };
    } catch (err) {
        console.log(err);
        throwCustomError(feedback.addVoteToCollectionFailure);
    }
};

export const getActiveCollections = async () => {
    try {
        const querySnapshot = await firestore
            .collection('Collections')
            .where('isActive', '==', true)
            .get();

        const collectionsArray = [];
        querySnapshot.forEach((doc) => {
            collectionsArray.push({ id: doc.id, ...doc.data() });
        });

        return {
            status: 200,
            msg: feedback.getActiveCollectionsSuccess,
            collections: collectionsArray,
        };
    } catch (err) {
        throwCustomError(feedback.getActiveCollectionsFailure);
    }
};

export const getActiveFullCollections = async () => {
    try {
        const querySnapshot = await firestore
            .collection('Collections')
            .where('isActive', '==', true)
            .get();

        const collectionsArray = [];
        querySnapshot.forEach(async (doc) => {
            const votesArray = [];
            const votesSnapshot = await firestore
                .collection('Collections')
                .doc(doc.id)
                .collection('Votes')
                .get();
            votesSnapshot.forEach((doc) => {
                votesArray.push(doc.data());
            });

            const productsArray = [];
            const productsSnapshot = await firestore
                .collection('Collections')
                .doc(doc.id)
                .collection('Products')
                .get();
            productsSnapshot.forEach((doc) => {
                productsArray.push(doc.data());
            });

            collectionsArray.push({
                id: doc.id,
                products: productsArray,
                votes: votesArray,
                ...doc.data(),
            });
        });

        return {
            status: 200,
            msg: feedback.getActiveFullCollectionsSuccess,
            collections: collectionsArray,
        };
    } catch (err) {
        console.log(err);
        throwCustomError(feedback.getActiveFullCollectionsFailure);
    }
};

export const getCollectionById = async (collectionId) => {
    try {
        const doc = await firestore
            .collection('Collections')
            .doc(collectionId)
            .get();
        if (doc.data()) {
            return {
                status: 200,
                msg: feedback.getCollectionByIdSuccess,
                collection: { id: doc.id, ...doc.data() },
            };
        } else {
            throw new Error();
        }
    } catch (err) {
        throwCustomError(feedback.getCollectionByIdFailure);
    }
};

export const getFullCollectionById = async (collectionId) => {
    try {
        const doc = await firestore
            .collection('Collections')
            .doc(collectionId)
            .get();
        if (doc.data()) {
            const votesArray = [];
            const votesSnapshot = await firestore
                .collection('Collections')
                .doc(doc.id)
                .collection('Votes')
                .get();
            votesSnapshot.forEach((doc) => {
                votesArray.push(doc.data());
            });

            const productsArray = [];
            const productsSnapshot = await firestore
                .collection('Collections')
                .doc(doc.id)
                .collection('Products')
                .get();
            productsSnapshot.forEach((doc) => {
                productsArray.push(doc.data());
            });

            return {
                status: 200,
                msg: feedback.getFullCollectionByIdSuccess,
                collection: {
                    id: doc.id,
                    products: productsArray,
                    votes: votesArray,
                    ...doc.data(),
                },
            };
        } else {
            throw new Error();
        }
    } catch (err) {
        throwCustomError(feedback.getFullCollectionByIdFailure);
    }
};

export const getAllCollections = async () => {
    try {
        const querySnapshot = await firestore.collection('Collections').get();

        const collectionsArray = [];
        querySnapshot.forEach((doc) => {
            collectionsArray.push({ id: doc.id, ...doc.data() });
        });

        return {
            status: 200,
            msg: feedback.getAllCollectionsSuccess,
            collections: collectionsArray,
        };
    } catch (err) {
        throwCustomError(feedback.getAllCollectionsFailure);
    }
};

export const getAllFullCollections = async () => {
    try {
        const querySnapshot = await firestore.collection('Collections').get();

        const collectionsArray = [];
        querySnapshot.forEach(async (doc) => {
            const votesArray = [];
            const votesSnapshot = await firestore
                .collection('Collections')
                .doc(doc.id)
                .collection('Votes')
                .get();
            votesSnapshot.forEach((doc) => {
                votesArray.push(doc.data());
            });

            const productsArray = [];
            const productsSnapshot = await firestore
                .collection('Collections')
                .doc(doc.id)
                .collection('Products')
                .get();
            productsSnapshot.forEach((doc) => {
                productsArray.push(doc.data());
            });

            collectionsArray.push({
                id: doc.id,
                products: productsArray,
                votes: votesArray,
                ...doc.data(),
            });
        });

        return {
            status: 200,
            msg: feedback.getAllFullCollectionsSuccess,
            collections: collectionsArray,
        };
    } catch (err) {
        throwCustomError(feedback.getAllFullCollectionsFailure);
    }
};

export const updateCollection = async (collection) => {
    try {
        const today = new Date();
        if (
            collection.startCollectionDate <= today &&
            collection.endCollectionDate >= today
            ) {
                collection.isActive = true;
            } else {
                collection.isActive = false;
            }
        if (
            collection.startCollectionVoteDate <= today &&
            collection.endCollectionVoteDate >= today
            ) {
                collection.canVote = true;
            } else {
                collection.canVote = false;
            }
        await firestore
            .collection('Collections')
            .doc(collection.id)
            .update({ ...collection });
        return {
            status: 200,
            msg: feedback.updateCollectionSuccess,
        };
    } catch (err) {
        console.log(err);
        throwCustomError(feedback.updateCollectionFailure);
    }
};

export const editCollectionVote = async (vote, collectionId) => {
    try {
        const oldVote = await firestore
            .collection('Collections')
            .doc(collectionId)
            .collection('Votes')
            .where('referenceId', '==', vote.referenceId)
            .get();
        oldVote.forEach(async (doc) => {
            await firestore
                .collection('Collections')
                .doc(collectionId)
                .collection('Votes')
                .doc(doc.id)
                .update(vote);
        });
    } catch (err) {
        console.log(err);
        throw new Error();
    }
};

export const editCollectionProduct = async (product, collectionId) => {
    try {
        await firestore
            .collection('Collections')
            .doc(collectionId)
            .collection('Products')
            .doc(product.id)
            .update({ ...product });
    } catch (err) {
        console.log(err);
        throw new Error();
    }
};

export const deleteCollectionById = async (collectionId) => {
    try {
        await firestore
            .collection('Collections')
            .doc(collectionId)
            .delete();
        return {
            status: 200,
            msg: feedback.deleteCollectionByIdSuccess,
        };
    } catch (err) {
        throwCustomError(feedback.deleteCollectionByIdFailure);
    }
};

export const deleteCollectionVote = async (voteId, collectionId) => {
    try {
        await firestore
            .collection('Collections')
            .doc(collectionId)
            .collection('Votes')
            .doc(voteId)
            .delete();
    } catch (err) {
        console.log(err);
        throw new Error();
    }
};

export const deleteCollectionProduct = async (productId, collectionId) => {
    try {
        await firestore
            .collection('Collections')
            .doc(collectionId)
            .collection('Products')
            .doc(productId)
            .delete();
    } catch (err) {
        console.log(err);
        throw new Error();
    }
};
