<template>
    <Metrics />
</template>

<script>
import Metrics from '../components/metrics/Metrics.vue';
export default {
    components: {
        Metrics,
    },
};
</script>
