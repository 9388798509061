<template>
    <div>
        <div class="collections-list__row">
            <h2 class="collections-list__title">{{ collection.title }}</h2>
            <button @click="toggleDetailsHandler">{{ showMoreText }}</button>
            <button @click="exportHandler">CSV</button>
        </div>
        <div v-if="showingDetails">
            <div class="collections-list__row">
                <div class="collections-list__header-cell">Producto</div>
                <div class="collections-list__header-cell">Categoría</div>
                <div class="collections-list__header-cell">Unidades</div>
                <div class="collections-list__header-cell">Votos</div>
                <div class="collections-list__header-cell">Puntuación</div>
            </div>
            <ProductDetail v-for="(product, index) in products" :key="product.id" :product="product" :index="index" />
        </div>
    </div>
</template>

<script>
import ProductDetail from './ProductDetail.vue';
export default {
    name: 'Result',
    components: {
        ProductDetail,
    },
    props: {
        collection: Object,
    },
    data() {
        return {
            showingDetails: false,
        };
    },
    computed: {
        products() {
            return this.collection.products.map((product) => {
                const productVotes = this.collection.votes.filter((vote) => vote.product === product.id);
                return { ...product, productVotes };
            });
        },

        showMoreText() {
            return !this.showingDetails ? 'Ver más' : 'Ver menos';
        },
    },
    methods: {
        async exportHandler() {
            try {
                const createCSV = await fetch('https://us-central1-riverlion-4411c.cloudfunctions.net/createCSV', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id: this.collection.id,
                        data: this.products,
                        title: this.collection.title
                    })
                });
                const response = await createCSV.json();
                window.open(response.path);
            } catch (err) {
                console.log(err);
            }
        },
        toggleDetailsHandler() {
            this.showingDetails = !this.showingDetails;
        },
    },
};
</script>

<style scoped>
.collections-list__row {
    display: flex;
    justify-content: space-between;
    align-content: center;
}
.collections-list__title {
    font-size: 1.5rem;
    font-weight: 600;
}
.collections-list__header-cell {
    width: 20%;
    font-weight: 600;
    padding: 1rem;
}

button {
    display: flex;
    font-size: 14px;
    font-weight: 700;
    color: #333;
    padding: 8px;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #4478a3;
    transition: all 150ms linear;
    cursor: pointer;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

button:hover {
    background-color: #4478a3;
    color: #fff;
}
</style>
