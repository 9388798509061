<template>
    <section>
        <Loader v-if="isLoading" />
        <template v-else>
            <ActionsBar @save="saveHandler" :save="true" :changes="hasChanges" v-if="!editing" />
            <div class="projects">
                <div class="projects__header">
                    <h3 class="projects__header-title">Editor de Proyectos</h3>
                    <p class="projects__header-text">
                        En esta sección podrás <b>editar, crear y modificar</b> los proyectos que aparecen en la página
                        principal.
                    </p>
                    <p class="projects__header-text">
                        Cada proyecto está compuesto de manera obligatoria por los siguientes campos:
                    </p>
                    <ul class="projects__header-list">
                        <li>Imagenes: Una o varias imágenes</li>
                        <li>Título: El título que se muestra en el proyecto</li>
                        <li>Texto: El texto adicional que se muestra justo debajo del título</li>
                    </ul>
                </div>

                <EditProjects
                    @projectData="handlerProjectData"
                    @projectRemove="handlerRemoveProject"
                    @editingEnable="editingHandler"
                    :projectData="projects"
                />
            </div>
        </template>
    </section>
</template>

<script>
import { getWebpageData, updateWebpageData } from '../services/firebase/webpageCRUD';

import { createToast } from '../utils/utils';

import Loader from '../components/generic/Loader.vue';
import ActionsBar from '../components/bars/ActionsBar.vue';

import EditProjects from '../components/cms/EditProjects.vue';
export default {
    components: {
        EditProjects,
        ActionsBar,
        Loader,
    },
    data() {
        return {
            loading: false,
            changes: false,
            editing: false,
            projects: [],
        };
    },
    methods: {
        // Handler to persist partnet info
        handlerProjectData(title, text, image, gallery, projectIndex) {
            this.changes = true;

            if (this.projects.some((project, index) => index === projectIndex)) {
                this.projects[projectIndex].title = title;
                this.projects[projectIndex].text = text;
                this.projects[projectIndex].image = image;
                this.projects[projectIndex].gallery = gallery;
                return;
            }

            this.projects.push({
                title,
                text,
                image,
                gallery,
            });
        },

        // Hanlder to remove partner data
        handlerRemoveProject(projectIndex) {
            this.changes = true;
            this.projects = this.projects.filter((project, index) => index !== projectIndex);
        },

        // Handler to save all data
        saveHandler() {
            updateWebpageData({
                projects: this.projects,
            })
                .then(({ msg }) => {
                    this.changes = false;
                    createToast({
                        text: msg,
                        type: 'success',
                        duration: 2500,
                    });
                })
                .catch(({ msg }) => {
                    createToast({
                        text: msg,
                        type: 'error',
                    });
                });
        },

        // Handler to fetch data
        fetchData() {
            this.loading = true;
            getWebpageData().then(({ webpageData }) => {
                const { projects } = webpageData;
                this.projects = projects;
                this.loading = false;
            });
        },

        // Emit a boolean to hide Save button
        editingHandler(value) {
            this.editing = value;
        },
    },
    computed: {
        isLoading() {
            return this.loading;
        },

        hasChanges() {
            return this.changes;
        },
    },
    created() {
        this.fetchData();
    },
};
</script>

<style lang="less">
@import '../assets/css/viewsStyles/projects.less';
</style>
