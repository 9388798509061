<template>
    <section class="editCategories">
        <button
            v-if="hasCategories && !isCreatingNewCategory"
            class="editCategories__no-sliders-button"
            @click="createProductHandler('new')"
        >
            <FontAwesomeIcon class="editCategories__button-icon" icon="plus-circle" />Añadir nueva categoría
        </button>
        <div v-if="!hasCategories && !isCreatingNewCategory" class="editCategories__no-proyects">
            <p class="editCategories__no-proyects-message">Actualmente no tienes ninguna categoría.</p>
            <button class="editCategories__no-proyects-button" @click="createProductHandler('new')">
                <FontAwesomeIcon class="editCategories__button-icon" icon="plus-circle" />Crear
            </button>
        </div>
        <div v-if="isCreatingNewCategory" class="editCategories__add-proyect">
            <section class="editCategories__add-proyect-header">
                <p class="editCategories__add-proyect-header-title">{{ textCategory }}</p>
                <section class="editCategories__add-proyect-header-buttons">
                    <button
                        @click="createProductHandler('add')"
                        class="editCategories__add-proyect-button"
                        :class="mandatoryFields"
                    >
                        <FontAwesomeIcon class="editCategories__button-icon" :icon="saveButtonIcon" />{{
                            saveButtonText
                        }}
                    </button>
                    <button @click="createProductHandler('cancel')" class="editCategories__add-proyect-button">
                        <FontAwesomeIcon class="editCategories__button-icon" icon="ban" />Cancelar
                    </button>
                </section>
            </section>
            <div class="editCategories__add-proyect-form">
                <input type="title" v-model="title" placeholder="Escribe el nombre de la categoría" />
            </div>
        </div>
        <div v-if="hasCategories && !isCreatingNewCategory" class="editCategories__wrapper">
            <div
                v-for="({ title, id }, index) in categoriesData"
                :key="`${index}-category`"
                class="editCategories__wrapper-items"
            >
                <div class="editCategories__wrapper-items-actions">
                    <button
                        class="editCategories__no-proyects-button edit"
                        @click="actionsProductsHandler(index, title)"
                    >
                        <FontAwesomeIcon class="editCategories__button-icon" icon="edit" />Editar
                    </button>
                    <button class="editCategories__no-proyects-button remove" @click="removeCategory(id)">
                        <FontAwesomeIcon class="editCategories__button-icon" icon="eraser" />Eliminar
                    </button>
                </div>
                <div class="editCategories__wrapper-items-inner">
                    <section>
                        <h2 class="editCategories__wrapper-items-title">{{ title }}</h2>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faEraser, faPlusCircle, faSave, faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faEdit, faEraser, faPlusCircle, faSave, faBan);
export default {
    components: {
        FontAwesomeIcon,
    },
    props: {
        categoriesData: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            isCreatingNewCategory: false,
            isEditing: false,
            title: '',
            index: null,
        };
    },
    methods: {
        // Handler to crear new slide
        createProductHandler(method) {
            if (method === 'new') {
                this.$emit('editingEnable', true);
                this.isCreatingNewCategory = true;
                return;
            }

            if (method === 'cancel') {
                this.isCreatingNewCategory = false;
                this.$emit('editingEnable', false);
                this.reset();
                return;
            }

            if (method === 'add') {
                this.$emit('categoriesData', this.title, this.index);
                this.isCreatingNewCategory = false;
                this.isEditing = false;
                this.$emit('editingEnable', false);

                return;
            }
        },

        // Handler to edit or remove slide
        actionsProductsHandler(index, title) {
            this.title = title;
            this.index = index;
            this.isCreatingNewCategory = true;
            this.isEditing = true;
            this.$emit('editingEnable', true);
            return;
        },

        removeCategory(productId) {
            this.$emit('categoryRemove', productId);
        },

        // Reset all properties on slide
        reset() {
            this.title = '';
            this.index = null;
        },
    },
    computed: {
        // Check if exist data in slider
        hasCategories() {
            return !!this.categoriesData?.length;
        },

        // Show text depends is editing or not
        saveButtonText() {
            return this.isEditing ? 'Guardar' : 'Añadir';
        },

        mandatoryFields() {
            if (this.title?.length) {
                return '';
            }
            return 'editSlider__add-newslider-button--not-allowed';
        },

        saveButtonIcon() {
            return this.isEditing ? 'save' : 'plus-circle';
        },

        textCategory() {
            return this.isEditing ? 'Editando una categoría' : 'Añadiendo nueva categoría';
        },
    },
};
</script>

<style lang="less">
@import '../../assets/css/products/edit-categories.less';
</style>
