<template>
    <div class="actionBar">
        <p class="actionBar__message" v-if="showChanges">Tienes cambios sin guardar.</p>
        <template>
            <button v-if="showSave" class="actionBar__button" :disabled="!showChanges" @click="saveHandler">
                <FontAwesomeIcon class="actionBar__button-icon" icon="save" />Guardar
            </button>
            <button v-if="showCancel" class="actionBar__button" @click="cancelHandler">
                <FontAwesomeIcon class="actionBar__button-icon" icon="ban" />Cancelar
            </button>
        </template>
    </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSave, faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faSave, faBan);
export default {
    components: {
        FontAwesomeIcon,
    },
    props: {
        save: {
            type: Boolean,
            default: false,
        },
        cancel: {
            type: Boolean,
            default: false,
        },
        changes: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        saveHandler() {
            this.$emit('save');
        },
        cancelHandler() {
            this.$emit('cancel');
        },
    },
    computed: {
        showSave() {
            return this.save;
        },

        showCancel() {
            return this.cancel;
        },

        showChanges() {
            return this.changes;
        },
    },
};
</script>

<style lang="less">
@import '../../assets/css/bars/actions-bar.less';
</style>
