<template>
    <section class="overview">
        <div class="overview__card">
            <div class="overview__header">
                <div class="overview__name">Usuarios</div>
            </div>
            <div class="overview__metrics">
                <div class="overview__metric">
                    <div class="overview__metric-value">{{ allUsers }}</div>
                    <div class="overview__metric-name">
                        Totales
                    </div>
                </div>
            </div>
        </div>
        <div class="overview__card">
            <div class="overview__header">
                <div class="overview__name">Productos</div>
            </div>
            <div class="overview__metrics">
                <div class="overview__metric">
                    <div class="overview__metric-value">{{ allProducts }}</div>
                    <div class="overview__metric-name">
                        Totales
                    </div>
                </div>
            </div>
        </div>
        <div class="overview__card">
            <div class="overview__header">
                <div class="overview__name">Votaciones</div>
            </div>
            <div class="overview__metrics">
                <div class="overview__metric">
                    <div class="overview__metric-value">{{ allCollections }}</div>
                    <div class="overview__metric-name">
                        Activas
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { getAllUsers } from '../../services/firebase/usersCRUD';
import { getAllProducts } from '../../services/firebase/productsCRUD';
import { getAllCollections } from '../../services/firebase/collectionsCRUD';

export default {
    data() {
        return {
            users: [],
            products: [],
            collections: [],
        };
    },
    methods: {
        fetchData() {
            getAllUsers().then(({ users, status }) => {
                if (status === 200) {
                    this.users = [...users];
                }
            });

            getAllProducts().then(({ products, status }) => {
                if (status === 200) {
                    this.products = [...products];
                }
            });

            getAllCollections().then(({ collections, status }) => {
                if (status === 200) {
                    this.collections = [...collections];
                }
            });
        },
    },

    computed: {
        allUsers() {
            return this.users?.length ?? this.users;
        },

        allProducts() {
            return this.products?.length ?? this.products;
        },

        allCollections() {
            return this.collections?.length ?? this.collections;
        },
    },

    created() {
        this.fetchData();
    },
};
</script>

<style lang="less">
@import '../../assets/css/metrics/metrics.less';
</style>
