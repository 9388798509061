import { firestore } from './firebase';
import { webPageId, webpageCRUDFeedbackStrings as feedback, throwCustomError } from './setup';

export const getWebpageData = async () => {
    try {
        const webpageData = await firestore
            .collection('Webpage')
            .doc(webPageId)
            .get();
        return {
            status: 200,
            msg: feedback.webpageDataSuccess,
            webpageData: webpageData.data(),
        };
    } catch (err) {
        throwCustomError(feedback.webpageDataFailure);
    }
};

export const updateWebpageData = async (webpageData) => {
    try {
        await firestore
            .collection('Webpage')
            .doc(webPageId)
            .set(webpageData, { merge: true });
        return {
            status: 200,
            msg: feedback.updateWebpageDataSuccess,
        };
    } catch (err) {
        throwCustomError(feedback.updateWebpageDataFailure);
    }
};

export const copyWebpageData = async () => {
    try {
        const webpageData = await firestore
            .collection('Webpage')
            .doc(webPageId)
            .get();
        await firestore
            .collection('WebpageBackup')
            .add(webpageData.data())
        console.log('DONE');
    } catch (err) {
        console.log('ERROR: ' + err)
        //throwCustomError(feedback.webpageDataFailure);
    }
};