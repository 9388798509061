import VueRouter from 'vue-router';

import About from '../views/About.vue';
import Contact from '../views/Contact.vue';
import Cookies from '../views/Cookies.vue';
import Partners from '../views/Partners.vue';
import SecondaryPartners from '../views/SecondaryPartners.vue';
import Privacity from '../views/Privacity.vue';
import Terms from '../views/Terms.vue';
import Projects from '../views/Projects.vue';
import Servicios from '../views/Servicios.vue';
import Slider from '../views/Slider.vue';
import Post from '../views/Post.vue';
import PostsList from '../views/PostsList.vue';
import BlogCategories from '../views/BlogCategories.vue';
import ProductsCategories from '../views/ProductsCategories.vue';
import Products from '../views/Products.vue';
import Presentation from '../views/Presentation.vue';
import Collections from '../views/Collections.vue';
import Results from '../views/Results.vue';
import UsersInvitation from '../views/UsersInvitation.vue';
import Users from '../views/Users.vue';
import Files from '../views/Files.vue';

export const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/about',
            component: About,
        },
        {
            path: '/',
            component: Presentation,
        },
        {
            path: '/contact',
            component: Contact,
        },
        {
            path: '/files',
            component: Files,
        },
        {
            path: '/cookies',
            component: Cookies,
        },
        {
            path: '/partners',
            component: Partners,
        },
        {
            path: '/secondary-partners',
            component: SecondaryPartners,
        },
        {
            path: '/privacity',
            component: Privacity,
        },
        {
            path: '/terms',
            component: Terms,
        },
        {
            path: '/projects',
            component: Projects,
        },
        {
            path: '/servicios',
            component: Servicios,
        },
        {
            path: '/slider',
            component: Slider,
        },
        {
            path: '/blog-categories',
            component: BlogCategories,
        },
        {
            path: '/posts-list',
            component: PostsList,
        },
        {
            path: '/new-post',
            component: Post,
        },
        {
            path: '/products-categories',
            component: ProductsCategories,
        },
        {
            path: '/products',
            component: Products,
        },
        {
            path: '/collections',
            component: Collections,
        },
        {
            path: '/results',
            component: Results,
        },
        {
            path: '/invitations',
            component: UsersInvitation,
        },
        {
            path: '/users',
            component: Users,
        },
        {
            path: '/',
            component: Presentation,
        },
    ],
});
