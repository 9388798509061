const toggleElement = ({ element, display = 'block', timer = 300 }) => {
    // If the current display matches the target display, do nothing
    const currentDisplay = window.getComputedStyle(element).display;
    if (currentDisplay === display) return;

    const isHidden = currentDisplay === 'none';

    // First add the opacity transition and set the default opacity for hidden elements
    element.style.opacity = isHidden ? '0' : '1';
    element.style.transition = `opacity ${timer}ms linear`;

    // Then do the toggling depending on the actual display
    setTimeout(() => {
        element.style[isHidden ? 'opacity' : 'display'] = isHidden ? '1' : 'none';
    }, isHidden ? 100 : timer);

    element.style[isHidden ? 'display' : 'opacity'] = isHidden ? display : '0';
};

/**
 * Arrow function to toggle out by selector
 * @param {String} selector: Valid element selector
 * @param {Number} timer: Fading animation timeout | default => 300ms
 */
 export const fadeOutBySelector = ({ selector, timer = 300 }) => {
    document.querySelectorAll(selector).forEach((element) => {
        toggleElement({ element, display: 'none', timer });
    });
};

/**
 * Arrow function to toggle in by selector
 * @param {String} selector: Valid element selector
 * @param {String} display: Which type of display has the element when showing up | default => block
 * @param {Number} timer: Fading animation timeout | default => 300ms
 */
 export const fadeInBySelector = ({ selector, display = 'block', timer = 300 }) => {
    document.querySelectorAll(selector).forEach((element) => {
        toggleElement({ element, display, timer });
    });
};

// Method to create and/or show a toast where needed
export const createToast = ({ text, type, class: extraClass, duration = 0 }) => {
    const toastClass = 'toast__alert';

    if (document.querySelector(`.${toastClass}`) === null) {
        const toast = document.createElement('div');
        const toastClasses = [toastClass, ...(extraClass ? [extraClass] : [])];
        toast.classList.add(...toastClasses);
        toast.innerHTML = `<i class='fas fa-times'></i> <span></span>`;
        document.querySelector('body').appendChild(toast);
    }

    const closeIcon = document.querySelector(`.${toastClass} .fa-times`);
    closeIcon.addEventListener('click', dismissToast);

    if (type) {
        document
            .querySelector(`.${toastClass}`)
            .classList.remove(`${toastClass}--success`, `${toastClass}--error`, `${toastClass}--warning`);
        document.querySelector(`.${toastClass}`).classList.add(`${toastClass}--${type}`);
    }

    document.querySelector(`.${toastClass} span`).innerText = text;
    fadeInBySelector({ selector: `.${toastClass}` });

    if (duration !== 0) {
        setTimeout(dismissToast, duration);
    }
};

// Method to hide a toast where needed
const dismissToast = () => {
    const closeIcon = document.querySelector(`.toast__alert .fa-times`);
    fadeOutBySelector({ selector: '.toast__alert' });

    if (closeIcon) {
        closeIcon.removeEventListener('click', dismissToast);
    }
};
