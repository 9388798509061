<template>
    <section>
        <Loader v-if="isLoading" />
        <template v-else>
            <ActionsBar @save="saveHandler" :save="true" />
            <div class="contact">
                <div class="contact__header">
                    <h3 class="contact__header-title">Editor del texto de Contacto</h3>
                    <p class="contact__header-text">
                        En esta sección podrás <b>editar, crear y modificar</b> la sección de Contacto.
                    </p>
                    <p class="contact__header-text">
                        Solo se admite texto en esta sección y actualmente el editor
                        <b>no soporta la subida de imágenes</b>.
                    </p>
                </div>

                <VueEditor placeholder="Sección de contacto" v-model="contact" />
            </div>
        </template>
    </section>
</template>

<script>
import { VueEditor } from 'vue2-editor';

import { getWebpageData, updateWebpageData } from '../services/firebase/webpageCRUD';

import { createToast } from '../utils/utils';

import Loader from '../components/generic/Loader.vue';
import ActionsBar from '../components/bars/ActionsBar.vue';
export default {
    components: {
        ActionsBar,
        Loader,
        VueEditor,
    },
    data() {
        return {
            loading: false,
            changes: false,
            contact: '',
        };
    },
    methods: {
        // Handler to save all data
        saveHandler() {
            updateWebpageData({
                contact: this.contact,
            })
                .then(({ msg }) => {
                    this.changes = false;
                    createToast({
                        text: msg,
                        type: 'success',
                        duration: 2500,
                    });
                })
                .catch(({ msg }) => {
                    createToast({
                        text: msg,
                        type: 'error',
                    });
                });
        },

        // Handler to fetch data
        fetchData() {
            this.loading = true;
            getWebpageData().then(({ webpageData }) => {
                const { contact } = webpageData;
                this.contact = contact;
                this.loading = false;
            });
        },
    },
    computed: {
        isLoading() {
            return this.loading;
        },
    },
    created() {
        this.fetchData();
    },
};
</script>

<style lang="less">
@import '../assets/css/viewsStyles/contact.less';
</style>
