<template>
    <div>
        <div class="users-list__row">
            <h2 class="users-list__title">{{ userCompany }} - {{ userEmail }}</h2>
            <button v-if="showingDetails" @click="toggleDetailsHandler">-</button>
            <button v-else @click="toggleDetailsHandler">+</button>
        </div>
        <div v-if="showingDetails">
            <div class="users-list__row">
                <div class="users-list__cell">{{ userName }}</div>
                <div class="users-list__cell">{{ userPhone }}</div>
                <div class="users-list__cell">{{ userEmail }}</div>
                <div class="users-list__cell">
                    <button @click="deleteUserHandler">BORRAR</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { auth } from '../../services/firebase/firebase';
export default {
    name: 'UsersListItem',
    props: {
        user: Object,
    },
    data() {
        return {
            showingDetails: false,
        }
    },
    computed: {
        userName() {
            return this.user?.name || 'Sin nombre asignado'
        },
        userCompany() {
            return this.user?.company || 'Sin empresa asignada'
        },
        userPhone() {
            return this.user?.phone || 'Sin teléfono asignado'
        },
        userEmail() {
            return this.user?.email || 'Sin e-mail asignado'
        },
    },
    methods: {
        toggleDetailsHandler() {
            this.showingDetails = !this.showingDetails;
        },
        deleteUserHandler() {
            const params = {
                currentUserId: auth.currentUser.uid,
                userToDeleteId: this.user.id
            }
            this.$emit('deleteUser', params);
        },
    }
}
</script>

<style scoped>
.users-list__row {
    display: flex;
    justify-content: space-between;
    align-content: center;
}
.users-list__title {
    font-size: 1.5rem;
    font-weight: 600;
}
.users-list__cell {
    width: 25%;
    padding: 1rem;
}
</style>